import React from "react";
import {Col, Label, Row} from "reactstrap";
import {FRequest, withFilters} from "@jeremyglanum/filters";
import {SingleValueI2CDoughnut} from "../Reporting/SingleValueI2CDoughnut";
import {EvolText} from "../Reporting/TextRenderers";
import {Orange, Red, Green} from "../Reporting/utils";
import {createAnalyseByIdSelector} from "../../selectors/analyse";
import {createItemI2cFetch} from "../../tools/Filters/updateFunctions";
import LoadingButton from "../../common/LoadingButton";

const analyseSelector = createAnalyseByIdSelector();

@withFilters(({analyses: {value}}) => ({
  analyse: analyseSelector({id: !!value[0] ? value[0].value : null})
}))
class TotalI2CHeader extends React.PureComponent {
  state = {
    loading: false
  };

  print = async () => {
    this.setState({loading: true});
    await this.props.loadPrint();
    if (!!document.getElementById('frm'))
      document.body.removeChild(document.getElementById('frm'));

    const content = document.getElementById("printable");
    const ifrm = document.createElement("iframe");

    ifrm.setAttribute("id", "frm");
    ifrm.style.visibility = "hidden";
    ifrm.style.width = "1000px";
    ifrm.style.height = "0px";
    document.body.appendChild(ifrm);

    const frm = document.getElementById("frm").contentDocument;

    frm.open();
    frm.write(document.head.innerHTML);
    frm.write(content.innerHTML);
    setTimeout(() => {
      frm.close();

      const pri = document.getElementById("frm").contentWindow;

      pri.focus();
      pri.print();
      this.setState({loading: false});
    }, 2000);
  };

  vagueI2cFetch = createItemI2cFetch({
    params: {
      lastVagueDiff: true,
      lastVagueValue: true
    },
    withLabels: true,
    by: 'vague'
  });

  render(){
    const {loading} = this.state;
    const {passageLow, passageHigh, section, loadingResponse} = this.props;

    return (
      <Row>
        <div className="d-flex flex-grow-1 w-100">
        <Col xs="4">
          <Label>
            <strong>
              {t('reporting.conformity_seuil')}
            </strong>
          </Label>
          <div>
            <i className='fa fa-circle' style={{color: Green}} />
            &nbsp;
            {t('reporting.up_or_equal')} {passageHigh}%
          </div>
          <div>
            <i className='fa fa-circle' style={{color: Orange}} />
            &nbsp;
            {t('reporting.between')} {passageLow}% {t('reporting.and')} {passageHigh}%
          </div>
          <div>
            <i className='fa fa-circle' style={{color: Red}} />
            &nbsp;
            {t('reporting.less_than')} {passageLow}%
          </div>
        </Col>
        <Col xs="8">
          <Row>
            <Col xs="3" style={{alignSelf:"self-end"}}>
              <div className="flex-grow-1 flex-column d-flex pl-4 align-items-center justify-content-center">
                    <span className="text-muted text-center">
                      {t('reporting.i2c_rappel', {I2C: Labels.i2c})}<br/>{t('reporting.previous_vague')}
                      </span>
                    <FRequest
                      dynamicParameters={[
                        "clients",
                        "analyses",
                        "vagues",
                        "zones_exploitation"
                      ]}
                      updateFunc={this.vagueI2cFetch}>
                      {(result, loading) => {
                        if (loading && !result)
                          return <div className="text-center">{t('reporting.loading')}</div>;

                        if (!result || !result.length)
                          return <div className="text-center">{t('reporting.no_data')}</div>;
                        const data = !!result && !!result.length ? result[0] : null;
                        const vagueNb = data.vague > 1 ? data.vague - 1 : data.vague;
                        const vague = !!this.props.analyse.vagues && !!this.props.analyse.vagues.length ?
                          !!this.props.analyse.vagues.find(a => a.vague === vagueNb)
                            ? this.props.analyse.vagues.find(a => a.vague === vagueNb).label
                            : this.props.analyse.vagues[0].label
                        : null;
                        const lastVagueI2C = data.lastVagueValue;
                        if (data.vague === 1)
                          return <div className="text-center">{t('reporting.no_data')}</div>;
                        return (
                          <>
                            <span className="text-center">{vague}</span>
                            <div className="flex-grow-1 d-flex">
                              <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1" style={{width: 100}}>
                                <SingleValueI2CDoughnut
                                  passageLow={passageLow}
                                  passageHigh={passageHigh}
                                  value={lastVagueI2C}
                                  fontSize={15}
                                  size={60}/>
                              </div>
                            </div>
                          </>
                        )
                      }}
                    </FRequest>
              </div>
            </Col>
            <Col xs="8">
            <div style={{width: 100}} className="flex-grow-1 d-flex justify-content-center flex-column">
              <Label className="text-center">
                <strong>
                  {Labels.i2c}
                </strong>
              </Label>
              <FRequest
                updateFunc={this.vagueI2cFetch}
                dynamicParameters={[
                  "clients",
                  "analyses",
                  "vagues",
                  "zones_exploitation"
                ]}>
                {(result, loading) => {
                  if (loading && !result)
                    return <div>{t('reporting.loading')}</div>;

                  if (!result || !result.length)
                    return <div className="text-center">{t('reporting.no_data')}</div>;

                  const data = !!result && !!result.length ? result[0] : null;

                  const value = !!data ? data.value : null;
                  const label = !!data ? data.label : null;
                  const diff = data.lastVagueDiff;
                  return (
                    <Row className="flex-nowrap">
                    <div className="d-flex flex-grow-1">
                      <div className="flex-grow-1 d-flex align-items-center justify-content-center">
                        <div className="text-center d-flex flex-column align-items-center">
                          <span>{label}</span>
                          <SingleValueI2CDoughnut
                            passageLow={passageLow}
                            passageHigh={passageHigh}
                            value={value}
                            size={120}/>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center" style={{width: 100, paddingLeft:"20%"}}>
                    <b><EvolText value={diff} style={{fontSize: "35px"}}/></b>
                    </div>
                    </Row>
                  )
                }}
              </FRequest>
            </div>
            </Col>
            <Col xs="1">
              {!this.props.print && (section === 'themes' || section === 'detailed') &&
              <LoadingButton loading={loading} disabled={loading || !!loadingResponse} size="sm" onClick={this.print}>
                <span className='fa fa-print'/>
              </LoadingButton>}
            </Col>
          </Row>
        </Col>
        </div>
      </Row>
    )
  }
}

export {TotalI2CHeader};
export default TotalI2CHeader;
