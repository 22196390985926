import React from "react";
import {Line} from "react-chartjs-2";
import {randomColor} from "randomcolor"
import makeAnimated from "react-select/animated";
import Select from "react-select";
import {ocmDarkBlue} from "../../../../constants/ui";
import {createItemI2cFetch} from "../../../tools/Filters/updateFunctions";
import {FRequest} from "@jeremyglanum/filters";

class I2CEvolution extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selection: {label: t('reporting.global_i2c', {I2C: Labels.i2c}), value: 'global'},
      startVague: 0,
      endVague: 0
    }
  }

  updateFunc = createItemI2cFetch({
    params: {},
    by: 'vagueByQuestionnaireItem',
    withLabels: true,
    labelModelName: 'Theme',
    level: [1]
  });

  render() {
    return (
      <section className="card card-fluid m-0 flex-grow-1 d-flex flex-column">
        <header className="card-header d-flex">
          <span className="flex-grow-1">{t('reporting.i2c_evol', {I2C: Labels.i2c})}</span>
        </header>
        <div className="card-body d-flex">
          <FRequest
            dynamicParameters={[
              "clients",
              "analyses",
              "zones_exploitation"
            ]}
            updateFunc={this.updateFunc}>
            {(result, loading, error) => {
              if (loading && !result)
                return <div>{t('reporting.loading')}</div>;

              if (!result || !result.length)
                return <div class="text-center">{t('reporting.no_data')}</div>;

              result.forEach(r => {
                r.value = getAverage(r.items);
              });

              const vagues = result.map(r => ({value: r.label, label: r.label}));
              let chartData = {};

              if (this.state.selection.value === 'global') {
                chartData = {
                  labels: [],
                  datasets: [{
                    label: t('reporting.global_i2c', {I2C: Labels.i2c}),
                    data: [],
                    borderColor: ocmDarkBlue,
                    backgroundColor: '#fff'
                  }]
                };

                result.forEach(vague => {
                  chartData.datasets[0].data.push(vague.value);
                });
              }
              else if (this.state.selection.value === 'themes') {
                const tNb = [];

                result.forEach(v => {
                  v.items.forEach(theme => {
                    if (!tNb.find(t => t.id === theme.id))
                      tNb.push(theme);
                  })
                });

                chartData = {
                  labels: [],
                  datasets:
                    tNb.map(t => {
                      return {
                        label: t.label,
                        theme_id: t.id,
                        data: [],
                        borderColor: randomColor(),
                        backgroundColor: '#fff'
                      }
                    })
                };
                result.forEach(vague => {
                  tNb.forEach((t, idx) => {
                    const vagueTheme = vague.items.find(i => i.id === t.id);

                    chartData.datasets[idx].data.push(!!vagueTheme ? vagueTheme.value : null);
                  });
                });
              }

              result.forEach((d, i) => {
                if (!this.state.startVague && i === 0)
                  this.state.startVague = d;
                if (!this.state.endVague && i + 1 === result.length)
                  this.state.endVague = d;
                chartData.labels.push(`${d.label}`);
              });

              const options = {
                tooltips: {
                  callbacks: {
                    label: function (item, data){
                      return `${data.datasets[item.datasetIndex].label} : ${data.datasets[item.datasetIndex].data[item.index].toFixed(0)}%`;
                    }
                  }
                },
                maintainAspectRatio: false,
                elements: {
                  line: {
                    fill: false
                  }
                },
                scales: {
                  yAxes: [{
                    ticks: {
                      suggestedMax: 100,
                      beginAtZero: true
                    }
                  }],
                  xAxes: [{
                    ticks: {
                      min: `${this.state.startVague.label}`,
                      max: `${this.state.endVague.label}`
                    }
                  }]
                }
              };

              const customStyles = {
                container: (provided, state) => ({
                  ...provided,
                  flex: 1
                  // width: 'inherit'
                })
              };

              return (
                <>
                  <div style={{width: '60%', height: 275}}>
                    <Line
                      data={chartData}
                      options={options}/>
                  </div>
                  <div style={{width: '40%'}} className='ml-3'>
                    <Select
                      options={[
                        {label: t('reporting.global_i2c', {I2C: Labels.i2c}), value: 'global'},
                        {label: t('reporting.theme_i2c', {I2C: Labels.i2c}), value: 'themes'}
                      ]}
                      styles={customStyles}
                      menuPlacement={'top'}
                      components={makeAnimated()}
                      value={this.state.selection}
                      onChange={e => this.setState({selection: e})}/>
                    <div className='d-flex mt-2'>
                      <span className='pt-2'>{t('common.vague')}</span>
                      &nbsp;&nbsp;
                      <Select
                        options={vagues}
                        styles={customStyles}
                        menuPlacement={'top'}
                        components={makeAnimated()}
                        value={this.state.startVague}
                        onChange={e => this.setState({startVague: e})}/>
                      &nbsp;&nbsp;
                      <span className='pt-2'>{t('common.to')}</span>
                      &nbsp;&nbsp;
                      <Select
                        options={vagues}
                        styles={customStyles}
                        menuPlacement={'top'}
                        components={makeAnimated()}
                        value={this.state.endVague}
                        onChange={e => this.setState({endVague: e})}/>
                    </div>
                  </div>
                </>
              )
            }}
          </FRequest>
        </div>
      </section>
    )
  }
}

export {I2CEvolution};
export default I2CEvolution;
