import React from "react";
import {QuestionBadge, SubThemeBadge, ThemeBadge} from "../common/TreeItemsBadges";
import {CustomControlCheckbox} from "@jeremyglanum/ui";

export const checkableThemesTreeDecorators = {
  Container: ({node, name, terminal, listeners, onClick, decorators}) => {
    return (
      <div class="ocmTree__container" style={{paddingLeft: terminal ? 19 : ''}}>
        {!terminal && !!node.children && !!node.children.length &&
        <decorators.Toggle node={node} onClick={onClick}/>}
        <span class="ocmTree__header">
          {!terminal &&
          <React.Fragment>
            <i className="oi oi-list-rich cursor-pointer" onClick={listeners.handleRecursiveCheck}/>
            &nbsp;
          </React.Fragment>}
          <CustomControlCheckbox
            readOnly={!!node.nodeLocked}
            disabled={!!node.nodeLocked}
            indeterminate={!!node.indeterminate}
            className="custom-control-input"
            onChange={listeners.handleCheck}
            checked={!!node.checked}
            class="custom-control-inline m-0">
              {node.nodeModel === 'themeQuestion' ?
                <QuestionBadge/> :
                !!node.parent_id ?
                  <SubThemeBadge/> : <ThemeBadge/>}
            &nbsp;
            {node.nodeModel === 'themeQuestion' ? node.question.title : node.label}
            </CustomControlCheckbox>
          {/*<label className="custom-control custom-control-inline custom-checkbox m-0">*/}
          {/*<input type="checkbox"*/}
          {/*readOnly={!!node.nodeLocked}*/}
          {/*disabled={!!node.nodeLocked}*/}
          {/*className="custom-control-input"*/}
          {/*onChange={listeners.handleCheck}*/}
          {/*checked={!!node.checked}/>*/}
          {/*<div className="custom-control-label">*/}
          {/*{node.nodeModel === 'themeQuestion' ?*/}
          {/*<QuestionBadge/> :*/}
          {/*!!node.parent_id ?*/}
          {/*<SubThemeBadge/> : <ThemeBadge/>}*/}
          {/*&nbsp;*/}
          {/*{node.nodeModel === 'themeQuestion' ? node.question.title : node.label}*/}
          {/*</div>*/}
          {/*</label>*/}
       </span>
      </div>
    )
  }
};
