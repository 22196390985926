import React from "react";
import {Tree, decorators, utils} from "@jeremyglanum/neqo-tree";
import {DebounceInput} from "@jeremyglanum/ui";
import {CustomControlCheckbox} from "../common/CustomInputs";

const localDecorators = {
  HeaderContent: ({node, name, terminal, listeners}) => {
    return (
      <CustomControlCheckbox
        className="d-inline-block"
        checked={!!node.checked}
        label={node.label}
        indeterminate={node.indeterminate}
        onChange={listeners.handleCheck}/>
    );
  }
};

class CheckableTree extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      search: ''
    };

    if (!this.props.indeterminateCheck)
      utils.checkableTreeSetup(Array.isArray(this.props.data) ? this.props.data : [this.props.data]);
    else
      utils.indeterminateTreeSetup(Array.isArray(this.props.data) ? this.props.data : [this.props.data]);
  }

  componentDidUpdate(prevProps){
    if (this.props.data !== prevProps.data){
      if (!this.props.indeterminateCheck)
        utils.checkableTreeSetup(Array.isArray(this.props.data) ? this.props.data : [this.props.data]);
      else
        utils.indeterminateTreeSetup(Array.isArray(this.props.data) ? this.props.data : [this.props.data]);
      this.forceUpdate();
    }
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleCheck = (node, e) => {
    if (this.props.readOnly)
      return;
    if (!!this.props.customCheck)
      this.props.customCheck(node);
    else
      utils.indeterminateCheckFunction(node);
    this.forceUpdate();
    if (!!this.props.onChange)
      this.props.onChange(this.props.data);
  };

  handleRecursiveCheck = (node, e) => {
    if (this.props.readOnly)
      return;
    if (!!this.props.customCheck)
      this.props.customCheck(node);
    else
      utils.recursiveCheckFunction(node);
    this.forceUpdate();
    if (!!this.props.onChange)
      this.props.onChange(this.props.data);
  };

  render(){
    const {search} = this.state;
    const {searchDisabled, indeterminateCheck} = this.props;

    return (
      <div>
        {!searchDisabled &&
        <DebounceInput className="mb-2" value={search} onChange={this.handleChange}/>}
        <Tree
          search={!searchDisabled}
          searchValue={search}
          searchFields={['label']}
          listeners={{
            handleCheck: !indeterminateCheck ? this.handleRecursiveCheck : this.handleCheck
          }}
          decorators={localDecorators}
          {...this.props}/>
      </div>
    )
  }
}

export {CheckableTree};
export default CheckableTree;
