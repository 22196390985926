import React from "react";
import {
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import {connect} from "react-redux";
import {setModal} from "../../actions/modals";
import {decorators, CheckableTree} from "@jeremyglanum/neqo-tree";
import {startPending, stopPending} from "../../actions/ui";
import {copyThemeTree, fetchGenericThemes} from "../../actions/orm/Theme";
import {createThemeTreeByClientIdSelector} from "../selectors/theme";
import {CustomControlCheckbox} from "../common/CustomInputs";
import LoadingButton from "../common/LoadingButton";
import {QuestionBadge, SubThemeBadge, ThemeBadge} from "../common/TreeItemsBadges";

const localDecorators = {
  Container: ({node, name, terminal, listeners, onClick, decorators}) => {
    return (
      <div class="ocmTree__container">
        {!terminal && !!node.children && !!node.children.length &&
        <decorators.Toggle node={node} onClick={onClick}/>}
        <span class="ocmTree__header">
              {node.nodeModel === 'themeQuestion' ?
                <QuestionBadge/> :
                !!node.parent_id ?
                  <SubThemeBadge/> : <ThemeBadge/>}
          &nbsp;
          <CustomControlCheckbox
            className={'custom-control-inline m-0'}
            indeterminate={node.indeterminate}
            onChange={listeners.handleCheck}
            label={node.nodeModel === 'themeQuestion' ? node.question.title : node.label}
            checked={!!node.checked}/>
       </span>
      </div>
    )
  }
};

const themeTreeSelector = createThemeTreeByClientIdSelector();

@connect(({ocmModals : {copyGenericThemeTreeItems : {targetClientId, resolve, reject}}}) => ({
  clientId: targetClientId,
  resolve,
  reject
}))
class CopyGenericThemeTreeItems extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      closing: false,
      tree: [],
      toggledTree: false
    };
  }

  processCopy = async () => {
    const {clientId} = this.props;
    const {tree} = this.state;

    const horizontalTree = flatTree(tree);
    const checkedThemeIds = horizontalTree.filter(n => n.nodeModel === 'theme' && (!!n.checked || !!n.indeterminate)).map(i => i.id);
    const checkedThemeQuestionIds = horizontalTree.filter(n => n.nodeModel === 'themeQuestion' && (!!n.checked || !!n.indeterminate)).map(i => i.id);

    this.setState({loading: true});
    await this.props.dispatch(copyThemeTree({
      client_id: clientId,
      themes_id: checkedThemeIds,
      themes_questions_id: checkedThemeQuestionIds
    }));
    horizontalTree.forEach(i => {
      i.indeterminate = false;
      i.checked = false;
    });
    this.setState({loading: false});
  };

  nodeMatchFunction = (node, searchValue, searchFields) => {
    const searchLowerCase = searchValue.toLowerCase();
    if (!node.question && node.label.toLowerCase().includes(searchLowerCase))
      return true;
    else if (!!node.question && node.question.title.toLowerCase().includes(searchLowerCase))
      return true;
    return false;
  };

  afterToggle = () => {
    const {tree} = this.state;

    this.setState({
      toggledTree: tree.find(node => !!node.toggled)
    })
  };

  resolve = async () => {
    const {clientId} = this.props;
    const {tree} = this.state;

    const horizontalTree = flatTree(tree);
    const checkedThemeIds = horizontalTree.filter(n => n.nodeModel === 'theme' && (!!n.checked || !!n.indeterminate)).map(i => i.id);
    const checkedThemeQuestionIds = horizontalTree.filter(n => n.nodeModel === 'themeQuestion' && (!!n.checked || !!n.indeterminate)).map(i => i.id);

    this.setState({loading: true});
    await this.props.dispatch(copyThemeTree({
      client_id: clientId,
      themes_id: checkedThemeIds,
      themes_questions_id: checkedThemeQuestionIds
    }));
    horizontalTree.forEach(i => {
      i.indeterminate = false;
      i.checked = false;
    });
    this.setState({loading: false});
    this.props.resolve();
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setModal('copyGenericThemeTreeItems', false));
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  async componentDidMount(){
    this.props.dispatch(startPending());
    await this.props.dispatch(fetchGenericThemes());
    this.props.dispatch(stopPending());

    const tree = themeTreeSelector({clientId: null});

    this.setState({tree});
  }

  toggleAll = () => {
    const {toggledTree, tree} = this.state;

    function toggle(tree){
      tree.forEach(node => {
        node.toggled = !toggledTree;
        if (!!node.children && !!node.children.length)
          toggle(node.children);
      });
    }

    toggle(tree);
    this.setState({
      toggledTree: !toggledTree,
      tree: tree
    });
  };

  render(){
    const {loading, closing, tree, toggledTree} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close} scrollable={true}>
        <ModalHeader toggle={this.closing}>
          {t('theme_question_view.import_elements')}
        </ModalHeader>
        <ModalBody>
          <div class="mb-2">
            <Button size="sm" color="primary" onClick={this.toggleAll}>
              {!toggledTree ? t('common.unfold_all') : t('common.bend_all')}
            </Button>
          </div>
          <CheckableTree
            afterToggle={this.afterToggle}
            data={tree}
            nodeMatchFunction={this.nodeMatchFunction}
            showMatchedPath={true}
            decorators={localDecorators}/>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.reject}>{t('common.close')}</Button>
          <LoadingButton
            loading={loading}
            disabled={loading}
            color="success"
            onClick={this.resolve}>
            {t('common.valid')}
          </LoadingButton>
        </ModalFooter>
      </Modal>
    )
  }
}

export default CopyGenericThemeTreeItems;
export {CopyGenericThemeTreeItems};
