import React from "react";
import {FileInput} from "@jeremyglanum/ui";
import {api} from "../../tools/api";
import {Button} from "reactstrap";

class WritableFileList extends React.PureComponent {
  uploadFile = (e) => {
    const file = e.target.files[0];
    const extension = file.name.split(".");
    let defaultName = "";
    extension.map((n, i) => {
      if (i !== extension.length - 1)
        defaultName += i === 0 ? n : `.${n}`;
    });

    const reader = new FileReader();
    const {onChange, value, name} = this.props;

    reader.onload = async (e) => {
      const response = await api.file.upload({data: `extension:${extension[extension.length - 1]};${e.target.result}`});

      onChange({
        target: {
          name,
          value: [
            ...value,
            {
              filename: defaultName,
              path: response.path
            }]
        }
      });
    };
    reader.readAsDataURL(file);
  };

  removeFile = (idx) => {
    const {onChange, value, name} = this.props;

    const newValue = value.filter((v, index) => index !== idx);

    onChange({
      target: {
        name,
        value: newValue
      }
    })
  };

  render(){
    const {value} = this.props;

    return (
      <div class="ocm-file-list">
        {value.map(({path, filename}, idx) => {
          return (
            <div key={idx} class="file">
              <a href={path}
                 title={filename}
                 target="_blank"
                 class="file-link text-overflow-ellipsis">
                {filename}
              </a>
              <i class="fa fa-times link remove-button"
                 onClick={(e) => {
                   this.removeFile(idx);
                 }}
                 title={t('common.remove_file')}/>
            </div>
          )
        })}
        <FileInput
          as={Button}
          size="sm"
          class="file-adder"
          inputProps={{
            onChange:this.uploadFile,
            name: 'path'
          }}>
          {t('common.add_file')}
        </FileInput>
      </div>
    )
  }
}

export {WritableFileList};
export default WritableFileList;
