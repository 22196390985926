import React from "react";
import {Tree, decorators} from "@jeremyglanum/neqo-tree";
import {ColoredProgress} from "../../../common/ColoredProgress";
import {I2CText, EvolText} from "../TextRenderers";
import {Badge} from "reactstrap";
import {Red, Green, Orange} from "../utils";

class ThemesTree extends React.PureComponent {
  constructor(props) {
    super(props);

    this.decorators = {
      Container: ({terminal, node, listeners, onClick, decorators}) => {
        const {passageLow, passageHigh, hideEvol, client} = this.props;
        
        return (
          <div class="ocmTree__container" style={{
            borderBottom: !!node.toggled ? '2px dashed #BEBEBE' : undefined
          }}>
            {!terminal && !!node.children && !!node.children.length &&
            <decorators.Toggle node={node} onClick={onClick}/>}
            <span className="ocmTree__header d-flex flex-grow-1">
              <Badge color="primary" className="badge-subtle">
                {node.type === 'theme' ? t('common.theme_badge') : t('common.question_badge')}
              </Badge>
              &nbsp;
              <span class="flex-grow-1">
                {node.type === 'question' ?
                  <><b>{`${!!client.numerical && !!node.numericValue ? `${node.numericValue} ` : ''}`}</b>{`${node.question.title}`}</> :
                  node.label
                }
              </span>
              <ColoredProgress
                style={{
                  width: 200,
                  minWidth: 200
                }}
                striped={!(node.type === 'theme')}
                class="ml-2"
                colorLow={Red}
                colorMiddle={Orange}
                colorHigh={Green}
                passageLow={passageLow}
                passageHigh={passageHigh}
                value={node.value === 0 ? 1 : node.value}
                max={100}/>
              <I2CText
                style={{width: 50}}
                passageLow={passageLow}
                passageHigh={passageHigh}
                class="ml-2 font-weight-bold text-center"
                value={node.value}/>
              {!hideEvol &&
              <EvolText
                class="ml-2 font-weight-bold text-center"
                style={{width: 50}}
                value={node.diff}/>}
            </span>
          </div>
        )
      }
    };
  }

  render(){
    const {data} = this.props;

    return (
      <Tree
        data={data}
        decorators={this.decorators}/>
    )
  }
}

export {ThemesTree};
export default ThemesTree;
