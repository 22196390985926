import React from "react";
import {byItemI2cFetch} from "../../../tools/Filters/updateFunctions";
import _ from "lodash";
import {rootThemeLabelSelector, setThemeRanking} from "./AnalyseView";
import {EvolTable} from "../SyntheticView/EvolTable";
import {Card, CardBody, CardHeader, Col} from "reactstrap";
import {TopLoadingBar} from "@jeremyglanum/ui";
import {expandRendererProps} from "../../../tools/DatatableHelperFunctions";

export class AnalyseViewTree extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      loading: false,
      groupItemsExpanded: []
    }
  }

  componentDidMount(){
    this.setData();
  }

  componentDidUpdate(prevProps, prevState){
    const {axisValue, groupValue, analyseId, vague, axisItemsFilterValue} = this.props;

    if (groupValue !== prevProps.groupValue ||
      analyseId !== prevProps.analyseId ||
      vague !== prevProps.vague ||
      axisItemsFilterValue !== prevProps.axisItemsFilterValue){
      this.setData();
    } else if (axisValue !== prevProps.axisValue){
      const {items} = this.state;

      this.setState({
        groupItemsExpanded: [],
        items: items.map(i => ({
          ...i,
          children: [],
          loaded: false,
          loading: false
        }))
      })
    }
  }

  setData = async () => {
    const {axisValue, groupValue, analyseId, vague, axisItemsFilterValue} = this.props;

    this.setState({loading: true});

    const filter = !!axisItemsFilterValue.length ? axisValue.fappFilter : groupValue.fappFilter;

    let items = await byItemI2cFetch({
      by: 'item',
      params: {
        lastVagueValue: true,
        lastVagueDiff: true,
        itemType: groupValue.fappId
      },
      withLabels: true,
      labelModelName: groupValue.labelModel,
      filters: {
        analyseIds: [analyseId],
        vagues: [vague],
        ...filter,
        [axisValue.fappId]: !!axisItemsFilterValue.length ? axisItemsFilterValue.map(i => i.value) : undefined
      }
    });

    const type = groupValue.type === 'zoneExploitation' ? 'zone_exploitation' : undefined;

    items.forEach(i => {
      i.currentValue = i.value;
      i.evol = i.lastVagueDiff;
      i.type = type;
      i.hideEvol = i.firstVague;
    });

    if (groupValue.value === 'theme')
      setThemeRanking({
        items: items
      }, true);

    if (groupValue.value === 'question' || groupValue.value === 'subTheme')
      rootThemeLabelSelector({
        items: items,
        model: groupValue.labelModel
      }, true);

    this.setState({
      items: items,
      groupItemsExpanded: [],
      loading: false
    });
  };

  handleRowExpand = async (row, isExpand) => {
    const {axisValue, groupValue, analyseId, vague, axisItemsFilterValue} = this.props;
    const {items, groupItemsExpanded} = this.state;

    const fappFilterValue = groupValue.type === 'questionnaire' ? groupValue.fappFilter : axisValue.fappFilter;

    if (!!isExpand && !row.loaded){
      row.loading = true;

      let data = await byItemI2cFetch({
        by: 'item',
        params: {
          lastVagueValue: true,
          lastVagueDiff: true,
          itemType: axisValue.fappId
        },
        withLabels: true,
        labelModelName: axisValue.labelModel,
        filters: {
          analyseIds: [analyseId],
          vagues: [vague],
          [groupValue.fappId]: [row.id],
          ...fappFilterValue,
          [axisValue.fappId]: !!axisItemsFilterValue.length ? axisItemsFilterValue.map(i => i.value) : undefined
        }
      });

      const type = axisValue.type === 'zoneExploitation' ? 'zone_exploitation' : undefined;

      data.forEach(i => {
        i.currentValue = i.value;
        i.evol = i.lastVagueDiff;
        i.type = type;
        i.hideEvol = i.firstVague;
      });

      if (axisValue.value === 'theme')
        setThemeRanking({
          items: data
        }, true);

      if (axisValue.value === 'question' || axisValue.value === 'subTheme')
        rootThemeLabelSelector({
          items: data,
          model: axisValue.labelModel
        }, true);

      this.setState({
        items: items.map(i => {
          if (i.id === row.id)
            return {
              ...i,
              loading: false,
              loaded: true,
              items: data
            };
          return i;
        })
      })
    }

    this.setState({
      groupItemsExpanded: !!isExpand ? [...groupItemsExpanded, row.id] : groupItemsExpanded.filter(id => id !== row.id)
    })
  };

  expandRowRenderer = row => {
    const {axisValue, passageLow, passageHigh} = this.props;

    if (!!row.loading)
      return <i className="fa fa-circle-notch fa-spin text-primary"/>;

    if (!!row.loaded)
      return (
        <div style={{paddingLeft: 30}}>
          <EvolTable
            search={true}
            size={'sm'}
            dataName={axisValue.label}
            progressBar={true}
            exportProps={{
              name: 'Export'
            }}
            tableProps={{
              condensed:true,
              defaultSorted: [{
                dataField: axisValue.sortField,
                order: 'asc'
              }]
            }}
            additionalColumns={axisValue.value === 'subTheme' || axisValue.value === 'question' ? [{
              text: t('common.theme'),
              dataField: 'rootThemeLabel',
              headerAlign: 'center',
              align: 'center',
              sort:true
            }] : undefined}
            passageHigh={passageHigh}
            datas={row.items}
            passageLow={passageLow}/>
        </div>
      )
  };

  render(){
    const {items, loading, groupItemsExpanded} = this.state;
    const {
      passageLow,
      passageHigh,
      axisValue,
      groupValue
    } = this.props;

    return (
      <Card>
        <TopLoadingBar isLoading={loading}/>
        <CardHeader>
          {t("reporting.I2c par axe de comparaison", {I2C: Labels.i2c})}
        </CardHeader>
        <CardBody>
          <EvolTable
            search={true}
            dataName={groupValue.label}
            exportProps={{
              name: 'Export'
            }}
            progressBar={true}
            tableProps={{
              condensed:true,
              defaultSorted: [{
                dataField: groupValue.sortField,
                order: 'asc'
              }],
              expandRow: {
                onExpandAll: () => null,
                onExpand: this.handleRowExpand,
                expanded: groupItemsExpanded,
                renderer: this.expandRowRenderer,
                showExpandColumn: true,
                expandByColumnOnly: true,
                ...expandRendererProps,
                expandHeaderColumnRenderer: () => null
              }
            }}
            additionalColumns={groupValue.value === 'subTheme' || groupValue.value === 'question' ? [{
              text: t('common.theme'),
              dataField: 'rootThemeLabel',
              headerAlign: 'center',
              align: 'center',
              sort: true
            }] : undefined}
            passageHigh={passageHigh}
            datas={items.sort((a, b) => !!a.numericValueForSort ? a.numericValueForSort.localeCompare(b.numericValueForSort) : 0)}
            passageLow={passageLow}/>
        </CardBody>
      </Card>
    )
  }
}

export default AnalyseViewTree;
