import React from "react";
import {Tree, decorators, utils} from "@jeremyglanum/neqo-tree";
import {CustomInput, Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {DebounceInput} from "@jeremyglanum/ui";
import {CustomControlCheckbox} from "@jeremyglanum/ui";

const localDecorators = {
    ...decorators,
    Container: ({node, name, terminal, listeners, onClick, decorators}) => {
      return (
        <div class="ocmTree__container">
          {!terminal && !!node.children && !!node.children.length &&
          <decorators.Toggle node={node} onClick={onClick}/>}
          <span class="ocmTree__header">
            <CustomControlCheckbox
              className="d-inline-block"
              checked={!!node.checked}
              label={node.label}
              indeterminate={node.indeterminate}
              onChange={listeners.handleCheck}/>
          </span>
        </div>
      )
    }
};

class OrganisationsTree extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      search: ''
    };

    utils.checkableTreeSetup(this.props.data);
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleCheck = (node, e) => {
    node.checked = !node.checked;
    this.forceUpdate();
    if (!!this.props.onChange)
      this.props.onChange(this.props.data);
  };

  handleRecursiveCheck = (node, e) => {
    const {readOnly} = this.props;
    if (readOnly)
      return;

    utils.recursiveCheckFunction(node);
    this.forceUpdate();
    if (!!this.props.onChange)
      this.props.onChange(this.props.data);
  };

  organisationsSearchFunc = (data, searchValue, searchFields) => {
    function update(tree){
      tree.forEach(node => {
        node.hiddenBySearch = true;
        node.matched = false;
        for (let i = 0; i < searchFields.length; i++){
          if (!!node[searchFields[i]] && node[searchFields[i]].toLowerCase().includes(searchValue.toLowerCase())){
            node.hiddenBySearch = false;
            node.matched = true;
            node.toggled = true;
            break;
          }
        }
        if (!!node.children && !!node.children.length){
          if (update(node.children)){
            node.hiddenBySearch = false;
            node.toggled = true;
          }
        }
      });
      for (let i = 0; i < tree.length; i++){
        if (!tree[i].hiddenBySearch)
          return true;
      }
      return false;
    }
    update(data);

    function showMatchedPath(nodes, matched = false){
      nodes.forEach(node => {
        node.hiddenBySearch = !node.matched;
        if (!!matched){
          node.matched = matched;
          node.hiddenBySearch = !matched;
          node.toggled = true;
        }
        if (!!node.children && !!node.children.length){
          showMatchedPath(node.children, !!node.matched);
          if (!!(node.children.find(n => !!n.matched))){
            node.matched = true;
            node.hiddenBySearch = false;
            node.toggled = true;
          }
        }
      });
    }
    showMatchedPath(data);

    function checkItems(nodes){
      nodes.forEach(node => {
        node.checked = node.matched;
        if (!!node.children && !!node.children.length){
          checkItems(node.children);
          for (let i = 0; i < node.children.length; i++)
            if (!node.children[i].checked){
              node.checked = false;
              break;
            }
        }
      })
    }

    checkItems(data);

    if (!!this.props.onChange)
      this.props.onChange(data);
  };

  render(){
    const {search} = this.state;

    return (
      <div>
        <DebounceInput className="mb-2" onChange={this.handleChange} value={search}/>
        <Tree
          search={true}
          searchValue={search}
          searchFields={['label', 'title']}
          listeners={{
            handleCheck: this.handleRecursiveCheck,
//            handleRecursiveCheck: this.handleRecursiveCheck
          }}
          decorators={localDecorators}
          searchFunction={this.organisationsSearchFunc}
          {...this.props}/>
      </div>
    )
  }
}

export {OrganisationsTree};
export default OrganisationsTree;
