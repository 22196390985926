import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {createItemI2cFetch} from "../../../tools/Filters/updateFunctions";
import {EvolTable} from "../../Reporting/SyntheticView/EvolTable";
import {withFilters, FRequest} from "@jeremyglanum/filters";

@withFilters()
@withRouter
@connect()
class Historic extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  handleChange = async ({label, value}) => {
    await this.props.updateValue('vagues', [{label: label, value: value}]);
    this.props.history.push('/reporting/mono/detailed');
  };

  updateFunc = createItemI2cFetch({
    params: {
      lastVagueValue: true,
      lastVagueDiff: true
    },
    by: 'vague',
    withLabels: true
  });

  render(){
    const {passageLow, passageHigh} = this.props;

    return (
      <section className="card card-fluid m-0 flex-grow-1 d-flex flex-column">
        <header className="card-header d-flex align-items-center">
          <span className="flex-grow-1">{t('modals.historic')}</span>
        </header>
        <div className="card-body d-flex flex-column">
          <FRequest
            dynamicParameters={[
              "clients",
              "analyses",
              "zones_exploitation"
            ]}
            updateFunc={this.updateFunc}>
            {(result, loading, error) => {
              if (loading && !result)
                return <div>{t('reporting.loading')}</div>;

              if (!result || !result.length)
                return <div class="text-center">{t('reporting.no_data')}</div>;

              const datas = _.sortBy(result.map((r, i) => {
                return {
                  id: r.vague,
                  label: r.label,
                  labelForSort: typeof r.label  === "string" ? `${r.label.substring(3, r.label.length).concat(r.label.substring(0, 2))}` : r.label,
                  currentValue: r.value,
                  evol: r.lastVagueDiff,
                  hideEvol: r.firstVague
                }
              }), 'labelForSort').reverse();

              return (
                <EvolTable
                  defaultSort={{dataField:'labelForSort', order:'desc'}}
                  datas={datas}
                  search={true}
                  rapportButton={true}
                  passageLow={passageLow}
                  passageHigh={passageHigh}
                  dataName={t('common.vague')}
                  handleChange={this.handleChange}/>
              );
            }}
          </FRequest>
        </div>
      </section>
    )
  }
}

export {Historic};
export default Historic;
