import axios from "axios";
import {cDate} from "./date";
import {Query} from "@jeremyglanum/forepaas-utils";

const FOREPAAS_API_URL = !!_ENV.FOREPAAS_API_URL ? `${_ENV.FOREPAAS_API_URL}` : "https://ocm.forepaas.io";
const FOREPAAS_API_KEY = !!_ENV.FOREPAAS_API_KEY ? _ENV.FOREPAAS_API_KEY : "6213e5e2c32a8a1c000001776213e5f1c32a8a1c00000178";
const FOREPAAS_SECRET_KEY = !!_ENV.FOREPAAS_SECRET_KEY ? _ENV.FOREPAAS_SECRET_KEY : "0e262fe935f429da988da8d53ff4bebcea0d2c970fefb899f9dafe5dae6132f5";
const FOREPAAS_ACTION_ID = !!_ENV.FOREPAAS_ACTION_ID ? _ENV.FOREPAAS_ACTION_ID : "62027fabc30afcdb9126d1d6";
const FOREPAAS_FPRN = !!_ENV.FOREPAAS_FPRN ? _ENV.FOREPAAS_FPRN : "r5r1tgdq_kzcvfktq";
const FOREPAAS_REPOSITORY = !!_ENV.FOREPAAS_REPOSITORY ? _ENV.FOREPAAS_REPOSITORY : "5010ea60000eaf1000000001";

const post = (url, params) => {
  return axios.post(url, params).then(resp => {
    return resp.data;
  }).catch(err => {
    throw err;
  });
};

const normalPost = (url, data, config) => {
  return axios.post(url, data, config).then(resp => {
    return resp.data;
  }).catch(err => {
    throw err;
  });
};

const get = (url, params) => {
  return axios.get(url, {
    params : params
  }).then(resp => {
    return resp.data;
  }).catch(err => {
    throw err;
  });
};

const remove = (url, params) => {
  return axios.delete(url, {
    params : params
  }).then(resp => {
    return resp.data;
  }).catch(err => {
    throw err;
  })
};

const put = (url, params) => {
  return axios.put(url, params).then(resp => {
    return resp.data;
  }).catch(err => {
    throw err;
  });
};

const progressPlansData = {
  params: {
    dataByVague: {
      valueEstimated: true,
      value: true,
      progress: true,
      progressPlanStateDistribution: true,
      evolEstimated: true
    },
    dataByProgressPlan: {
      valueEstimated: true,
      value: true,
      state: true,
      progress: true,
      evolEstimated: true
    },
    dataByZoneExploitation: {
      progress: true,
      value: true,
      valueEstimated: true,
      evolEstimated: true,
      progressPlanStateDistribution: true
    }
  },
  filters: {
    vagues: [1],
    analyseIds: [2],
    zoneExploitationIds: [],
    progressPlanIds: [],
    prestataireIds: [], // ?
    taxonomyIds: [], // ?
    state: []
  }
};

const progressDataReturns = {
  progressPlanStateDistribution: {
    'not started': 2,
    'in progress': 1,
    'finished': 4
  }
};

const forepaas = {
  params: {
    valueByZone: {
      lastVagueValue: true,
      lastVagueDiff: true
    },
    valueByVague: {
      lastVagueValue: true,
      lastVagueDiff: true,
      i2cFullZones: true,
      zonesCount: true
    },
    valueByPrestataire: {
      lastVagueValue: true,
      lastVagueDiff: true,
      i2cFullZones: true,
      zonesCount: true
    },
    valueByQuestionnaireItem: {
      lastVagueValue: true,
      lastVagueDiff: true,
      i2cFullZones: true,
      zonesCount: true
    }
  },
  filters: {
    vagues: [1],
    analyseIds: [2],
    zoneExploitationIds: [],
    level: [],
    type: [],
    questionnaireIds: [],
    prestaitareIds: [],
    taxonomyIds: [], // ?
    LTEI2cFilter: 100
  }
};

const returnValues = {
  valueByVague: {
    ['id']: {
      id: 1,
      value: 100
    }
  },
  valueByZone: {
    ['id']: {
      id: 1,
      value: 100
    }
  },
  valueByPrestataire: {
    ['id']: {
      id: 1,
      value: 100
    }
  },
  valueByQuestionnaireItem: {
    ['level']: {
      ['id']: {
        id: 1,
        type: 'theme',
        level: 0,
        value: 100
      }
    }
  }
};

export const api = {
  forepaas: {
    getValueBy: async (scaleName, params = {}, filters) => {
      const scales = {
        item: 'valueByItem',
        prestataire: 'valueByPrestataire',
        zoneExploitation: 'valueByZone',
        zoneExploitationNotFilteredByZone: 'valueByZoneNotFilteredByZone',
        questionnaireItem: 'valueByQuestionnaireItem',
        vague: 'valueByVague',
        vagueByQuestionnaireItem: 'valueByVagueByQuestionnaireItem',
        progressPlanDataByVague: 'progressPlanDataByVague',
        progressPlanDataByProgressPlan: 'progressPlanDataByProgressPlan',
        progressPlanDataByZoneExploitation: 'progressPlanDataByZoneExploitation'
      };

      const query = new Query({
        endpoint: '/qb/ocm_analyses_data_new',
        query: {
          params: {
            [scales[scaleName]]: {
              ...params
            }
          },
          filters: {
            ...filters
          }
        }
      });

      const response = await query.execute().then(resp => resp.datas);

      return response[scales[scaleName]];
    },
    getZonesI2cByQuestionnaireItem : async ({level, id, i2cFilter, filters: {zoneIds, analyseIds, vagues}}) => {
      const query = new Query({
        endpoint: '/qb/ocm_analyses_data',
        query: {
          params:{
            zonesI2cByItem: {
              perimeter: {
                [level]: {
                  level: level,
                  ids: [id]
                }
              },
              i2cFilter: i2cFilter
            }
          },
          filters: {
            zoneIds,
            analyseIds,
            vagues
          }
        }
      });

      const {zonesI2cByItem} = await query.execute().then(resp => resp.datas);

      return zonesI2cByItem[level][id].items || {};
    },
    perimeterI2cByItems: async ({level, itemIds, breakPoint, filters: {zoneIds, analyseIds, vagues}}) => {
      const query = new Query({
        endpoint: '/qb/ocm_analyses_data',
        query: {
          params: {
            avgI2cByItem: {
              perimeter: {
                [level]: {
                  level: level,
                  ids: itemIds
                }
              },
              i2cTotal: true,
              i2cZones: true,
              zonesCount: true,
              i2cFilter: breakPoint
            }
          },
          filters: {
            zoneIds,
            analyseIds,
            vagues
          }
        }
      });

      const {avgI2cByItem} = await query.execute().then(resp => resp.datas);

      return avgI2cByItem[level];
    },
    ocmAnalysesData: ({dataTypes, filters: {zoneIds, analyseIds, vagues}}) => {
      const params = {
        avgI2cByItem: {
          perimeter: {
            '-1': {
              level: -1,
              ids: [1,2,3]
            },
            0: {
              level: 0,
              ids: [1,2,3]
            },
            1: {
              level: 1,
              ids: [1,2,3]
            }
          },
          i2cTotal: true,
          i2cZones: true,
          zonesCount: true,
          i2cFilter: 70
        },
        zonesI2cByItem: {
          id: 1,
          level: 1,
          i2cFilter: 70
        }
      };

      const returnValues = {
        avgI2cByItem: {
          '-1': {
            1: {
              id: 1,
              i2cTotal: 20,
              i2cZones: 30,
              zonesCount: 4
            },
            2: {
              id: 2,
              i2cTotal: 20,
              i2cZones: 30,
              zonesCount: 4
            }
          },
          0: {
            2: {
              id: 2,
              i2cTotal: 20,
              i2cZones: 30,
              zonesCount: 4
            }
          }
        },
        zonesI2cByItem: {
          id: 1,
          level: 1,
          zones: {
            1: {
              id: 1,
              i2c: 90
            }
          }
        }
      };

      const query = new Query({
        endpoint: '/qb/ocm_analyses_data',
        query: {
          params: dataTypes,
          filters: {
            zoneIds,
            analyseIds,
            vagues
          }
        }
      });

      return query.execute().then(response => response.datas);
    },
    actions: {
      getToken: () => {
        return post(`${FOREPAAS_API_URL}/iam/login`, {
          "app_id": "forepaas",
          "auth_mode": "apikey",
          "apikey": FOREPAAS_API_KEY,
          "secretkey": FOREPAAS_SECRET_KEY
        });
      },
      run: ({token}) => {
        return normalPost(`${FOREPAAS_API_URL}/dpe/v3/actions/${FOREPAAS_ACTION_ID}/start`, null, {
          params: {
            "fprn": FOREPAAS_FPRN,
            "app_id": "forepaas",
            "repository": FOREPAAS_REPOSITORY,
            "type": "cam",
            token
          }
        });
      }
    }
  },
  me: {
    changePassword: ({password, newPassword}) => {
      return put('/api/users/change-password', {
        password,
        new_password: newPassword
      });
    }
  },
  users: {
    resetPassword: ({email}) => {
      return post('/api/users/reset', {
        email
      });
    },
    setNewPassword: ({token, password}) => {
      return put('/api/users/password', {
        token,
        password
      });
    },
    login: ({email, password}) => {
      return post('/users/login', {
        email,
        password
      });
    },
    logout: () => {
      return get('/users/logout');
    },
    getMe: () => {
      return get('/api/me');
    },
    get: ({id}) => {
      return get(`/api/users/${id}`);
    },
    add: ({email, firstname, lastname, phone, path, active, level, client_id, prestataire_id, firm_id, profil_id, default_client_id, clients, zones_exploitation, sites, prestataires, locale, jobtitle}) => {
      return post('/api/users', {
        email,
        firstname,
        lastname,
        path : (!!path && path.startsWith('data')) ? path.split('base64,')[1] : undefined,
        active: typeof active === "string" ? active === "true" : active,
        level,
        client_id,
        prestataire_id,
        firm_id,
        profil_id,
        default_client_id,
        clients,
        sites,
        zones_exploitation,
        prestataires,
        locale,
        jobtitle,
        phone
      })
    },
    update: ({id, email, firstname, lastname, path, phone, active, client_id, prestataire_id, firm_id, profil_id, default_client_id, clients, zones_exploitation, sites, prestataires, locale, jobtitle, remove_clients, remove_sites, remove_zonesExploitations, remove_prestataires}) => {
      return put(`/api/users/${id}`, {
        id,
        email,
        firstname,
        lastname,
        path : (!!path && path.startsWith('data')) ? path.split('base64,')[1] : undefined,
        active: typeof active === "string" ? active === "true" : active,
        client_id,
        prestataire_id,
        firm_id,
        profil_id,
        default_client_id,
        insert_clients: (!!clients && !!clients.length) ? clients : undefined,
        insert_sites: (!!sites && !!sites.length) ? sites : undefined,
        insert_zones_exploitation: (!!zones_exploitation && !!zones_exploitation.length) ? zones_exploitation : undefined,
        insert_prestataires: (!!prestataires && !!prestataires.length) ? prestataires : undefined,
        delete_clients: (!!remove_clients && !!remove_clients.length) ? remove_clients : undefined,
        delete_sites: (!!remove_sites && !!remove_sites.length) ? remove_sites : undefined,
        delete_zones_exploitation: (!!remove_zonesExploitations && !!remove_zonesExploitations.length) ? remove_zonesExploitations : undefined,
        delete_prestataires: (!!remove_prestataires && !!remove_prestataires.length) ? remove_prestataires : undefined,
        locale,
        jobtitle,
        phone
      })
    },
    deleteUser: ({id}) => {
      return remove(`/api/users-true/${id}`);
    },
    updateClients: ({id, clients}) => {
      return post(`/api/users/${id}/clients`, {
        clients
      });
    },
    updatePrestataires: ({id, prestataires}) => {
      return post(`/api/users/${id}/prestataires`, {
        prestataires
      });
    }
  },
  prestataires: {
    get: () => {
      return get('/api/prestataires');
    },
    getOrganisation: () => {
      return get('/api/prestataires', {
        organisation: true
      })
    },
    getUsers: ({prestataire_id}) => {
      return get(`/api/prestataires/${prestataire_id}/users`);
    },
    getUsersResponsables: ({prestataire_id}) => {
      return get(`/api/prestataires/${prestataire_id}/users?respo`);
    },
    getRoot: () => {
      return get('/api/prestataires', {
        root: true
      })
    },
    update: ({id, label, code_postal, ville, pays, adresse, phone, path}) => {
      return put(`/api/prestataires/${id}`, {
        label,
        code_postal,
        ville,
        pays,
        adresse,
        phone,
        path: (!!path && path.startsWith('data')) ? path.split('base64,')[1] : undefined
      });
    },
    add: ({label, code_postal, ville, pays, adresse, phone, path, parent_id}) => {
      return post('/api/prestataires', {
        label,
        code_postal,
        ville,
        pays,
        adresse,
        phone,
        path: (!!path && path.startsWith('data')) ? path.split('base64,')[1] : undefined,
        parent_id
      });
    }
  },
  notifications: {
    postMessage: ({notification_id, content}) => {
      return post(`/api/notifications/${notification_id}/messages`, {
        content
      });
    },
    getMessages: ({notification_id}) => {
      return get(`/api/notifications/${notification_id}/messages`);
    }
  },
  messages: {
    fetchMessages: () => {
      return get(`/api/messageries`);
    },
    fetchOfProgressZone: ({progres_zone_exploitation_id}) => {
      return get(`/api/progres-zone-exploitations/${progres_zone_exploitation_id}/messages`);
    },
    read: ({message_id}) => {
      return get(`/api/messages/read/${message_id}`);
    },
    read2: ({message_id}) => {
      return post(`/api/messageries/${message_id}/read`)
    },
    postMessage: ({progres_zone_exploitation_id, content}) => {
      return post(`/api/progres-zone-exploitations/${progres_zone_exploitation_id}/messages`, {
        content
      });
    },
  },
  progress: {
    get: ({id}) => {
      return get(`/api/progres/view/${id}`);
    },
    getMultiple: ({ids}) => {
      return get(`/api/progres/global`, {id: ids});
    },
    getNotifications: ({progress_id}) => {
      return get(`/api/progres/${progress_id}/notifications`);
    },
    getResponsables: ({progress_id}) => {
      return get(`/api/progres/${progress_id}/responsables`);
    },
    getWithFilters: ({status, level, prestataires, vague, audits, analyses, themes, themes_questions, sites, zone_exploitations, clients, type_progres}) => {
      return get(`/api/progres`, {
        browser:true,
        status,
        level,
        prestataires,
        analyses,
        vague,
        audits,
        themes,
        themes_questions,
        sites,
        zone_exploitations,
        clients,
        type_progres
      });
    },
    addZoneExploitation: ({progress_id, zone_exploitation_id, user_id}) => {
      return post(`/api/progres/${progress_id}/add-one`, {
        zone_exploitation_id,
        user_id
      })
    }
  },
  modelActions: {
    add: ({label, date, modelId}) => {
      return post(`/api/type-progres/${modelId}/type-actions`, {
        label, date
      })
    },
    remove: ({id}) => {
      return remove(`/api/type-actions/${id}`);
    },
    update: ({id, ...params}) => {
      return put(`/api/type-actions/${id}`, params);
    }
  },
  progressModel: {
    get: ({clients, themes, themes_questions}) => {
      return get('/api/type-progres', {
        clients,
        themes,
        themes_questions
      });
    },
    add: ({client_id, label, level, actions, themes, themes_questions}) => {
      return post('/api/type-progres', {
        client_id,
        label,
        level,
        actions,
        themes : (!!themes && !!themes.length) ? themes : undefined,
        themes_questions: (!!themes_questions && !!themes_questions.length) ? themes_questions : undefined
      });
    },
    remove: ({id}) => {
      return remove(`/api/type-progres/${id}`);
    },
    update: ({id, ...params}) => {
      return put(`/api/type-progres/${id}`, params);
    }
  },
  client: {
    getThemes: ({client_id}) => {
      return get(`/api/clients/${client_id}/themes`);
    },
    getParameters: ({client_id}) => {
      return get(`/api/clients/${client_id}/parameters`);
    },
    getTaxonomies: ({client_id}) => {
      return get(`/api/clients/${client_id}/taxonomies`);
    },
    getQuestionnaires: ({client_id}) => {
      return get(`/api/clients/${client_id}/questionnaires`);
    },
    getResponsables: ({client_id}) => {
      return get(`/api/clients/${client_id}/responsables`);
    },
    getAllResponsables: ({client_id}) => {
      return get(`/api/clients/${client_id}/all-responsables`)
    },
    getOrganisation: ({client_id}) => {
      return get(`/api/clients/${client_id}/organisation?extended=true`);
    },
    getAudits: ({client_id}) => {
      return get(`/api/clients/${client_id}/audits`);
    },
    getReports: ({client_id}) => {
      return get(`/api/clients/${client_id}/reports`);
    },
    getUsers: ({client_id}) => {
      return get(`/api/clients/${client_id}/users`);
    },
    getClients: ({client_id}) => {
      return get(`/api/clients/${client_id}/organisation`);
    },
    getVisits: ({client_id}) => {
      return get(`/api/clients/${client_id}/rapports`);
    },
    add: ({parent_id, label, adresse, code_postal, ville, pays, phone, path, label_i2c, label_zone_exploitation, label_audit, responsables, main_responsables, characteristics, numerical}) => {
      return post('/api/clients', {
        parent_id,
        label,
        adresse,
        code_postal,
        ville,
        pays,
        phone,
        label_i2c,
        label_zone_exploitation,
        label_audit,
        responsables,
        main_responsables,
        characteristics,
        numerical,
        path: (!!path && path.startsWith('data')) ? path.split('base64,')[1] : undefined
      });
    },
    update: ({id, parent_id, label, adresse, code_postal, ville, pays, phone, path, prestataireIds, label_i2c, label_zone_exploitation, label_audit, responsables, main_responsables, characteristics, numerical}) => {
      return put(`/api/clients/${id}`, {
        parent_id,
        label,
        adresse,
        code_postal,
        ville,
        pays,
        phone,
        prestataires: prestataireIds,
        responsables,
        main_responsables,
        label_i2c,
        label_zone_exploitation,
        label_audit,
        characteristics,
        numerical,
        path: (!!path && path.startsWith('data')) ? path.split('base64,')[1] : undefined
      });
    }
  },
  progressZoneExploitation: {
    update: ({id, ...params}) => {
      return put(`/api/progres-zone-exploitations/${id}`, params);
    }
  },
  progressSite: {
    update: ({id, ...params}) => {
      return put(`/api/progres-sites/${id}`, params);
    }
  },
  progressClient: {
    update: ({id, ...params}) => {
      return put(`/api/progres-clients/${id}`, params);
    }
  },
  audits: {
    add: ({client_id, label, type, numerical, users, zone_exploitations}) => {
      return post(`/api/clients/${client_id}/audits`, {
        label,
        type,
        numerical,
        users,
        zone_exploitations
      });
    },
    update: ({audit_id, label, type, numerical, users, zone_exploitations}) => {
      return put(`/api/audits/${audit_id}`, {
        label,
        type,
        numerical,
        users,
        zone_exploitations
      });
    },
    cloneQuestionnaires: ({source_audit_id, target_audit_id}) => {
      return post(`/api/audits/${source_audit_id}/questionnaires/clone`, {
        audit_id: target_audit_id
      })
    },
    cloneQuestionnairesSameClient: ({source_audit_id, target_audit_id}) => {
      return post(`/api/audits/${source_audit_id}/questionnaires/clone-internal`, {
        audit_id: target_audit_id
      })
    },
    getQuestionnairesList: ({audit_id}) => {
      return get(`/api/audits/${audit_id}/questionnaires-list`);
    },
    getQuestionnaires: ({audit_id}) => {
      return get(`/api/audits/${audit_id}/questionnaires`);
    },
    getSections: ({audit_id}) => {
      return get(`/api/audits/${audit_id}/sections`);
    },
    freeze: ({audit_id, freeze}) => {
      return put(`/api/audits/${audit_id}/freeze/${!!freeze ? 'true' : 'false'}`);
    },
    getThemes: ({audit_id}) => {
      return get(`/api/audits/${audit_id}/themes`);
    },
    getAuditComplements: ({audit_id}) => {
      return get(`/api/audits/${audit_id}/complements`);
    },
    getClientComplements: ({client_id}) => {
      return get(`/api/clients/${client_id}/complements`);
    },
    getReports: ({audit_id}) => {
      return get(`/api/audits/${audit_id}/reports`);
    },
    addReport: ({audit_id, start_date, end_date, status}) => {
      return post(`/api/audits/${audit_id}/reports`, {
        start_date: !!start_date ? cDate.datePickerToSubmit(start_date, 'd-m-Y') : undefined,
        end_date: !!end_date ? cDate.datePickerToSubmit(end_date, 'd-m-Y') : undefined,
        status
      });
    }
  },
  reports: {
    getAll: () => {
      return get('/api/reports');
    },
    remove: ({id}) => {
      return remove(`/api/reports/${id}`);
    },
    freeze: ({ids}) => {
      return get('/api/reports/freeze/true', {
        report_id: ids
      })
    },
    add: ({start_date, end_date, status}) => {
      return post('/api/reports', {
        start_date: !!start_date ? cDate.datePickerToSubmit(start_date, 'd-m-Y') : undefined,
        end_date: !!end_date ? cDate.datePickerToSubmit(end_date, 'd-m-Y') : undefined,
        status
      });
    },
    update: ({id, start_date, end_date, status}) => {
      return put(`/api/reports/${id}`, {
        start_date: !!start_date ? cDate.datePickerToSubmit(start_date, 'd-m-Y') : undefined,
        end_date: !!end_date ? cDate.datePickerToSubmit(end_date, 'd-m-Y') : undefined,
        status
      });
    },
    getQuestions: ({report_id}) => {
      return get(`/api/reports/${report_id}/questions`);
    }
  },
  zoneExploitations: {
    fetchZoneExploitation: ({zone_exploitation_id}) => {
      return get(`/api/zone-exploitations/${zone_exploitation_id}`);
    },
    getPrestataires: ({zone_exploitations_id}) => {
      return get(`/api/zone-exploitations/${zone_exploitations_id}/prestataires`);
    },
    add: ({label, prestataires, brand_id, site_id, taxonomies, comment, schedules, main_responsable_id, responsable_ids, zone_characteristics}) => {
      return post('/api/zone-exploitations', {
        label,
        prestataires,
        site_id,
        taxonomies,
        comment,
        schedules,
        brand_id,
        responsable_ids,
        main_responsable_id,
        zone_characteristics
      })
    },
    addWithSite: ({label, brand_id, schedules, comment, prestataires, taxonomies, site_label, site_adresse, site_client_id, site_latitude, site_longitude, responsable_ids, main_responsable_id, site_responsables, site_main_responsables, zone_characteristics}) => {
      return post('/api/zone-exploitations', {
        label,
        prestataires: Array.isArray(prestataires) ? prestataires : [prestataires],
        taxonomies,
        comment,
        schedules,
        site_label,
        site_adresse,
        site_client_id,
        site_latitude,
        site_longitude,
        site_responsables,
        site_main_responsables,
        zone_characteristics,
        brand_id,
        responsable_ids,
        main_responsable_id
      })
    },
    update: ({id, label, brand_id, prestataires, taxonomies, comment, schedules, responsable_ids, main_responsable_id, zone_characteristics}) => {
      return put(`/api/zone-exploitations/${id}`, {
        label,
        prestataires,
        taxonomies,
        comment,
        schedules,
        brand_id,
        responsable_ids,
        main_responsable_id,
        zone_characteristics
      })
    },
    remove: ({id}) => {
      return remove(`/api/zone-exploitations/${id}`).then(r => true).catch(e => false);
    }
  },
  sites: {
    add: ({label, adresse, client_id, responsables, main_responsables}) => {
      return post('/api/sites', {
        label,
        adresse,
        client_id,
        responsables,
        main_responsables
      })
    },
    update: ({id, label, adresse, client_id, latitude, longitude, responsables, main_responsables}) => {
      return put(`/api/sites/${id}`, {
        label,
        adresse,
        latitude,
        longitude,
        client_id,
        responsables,
        main_responsables
      })
    }
  },
  parameter: {
    getAll: () => {
      return get('/api/parameters');
    },
    add: ({label, cible_id, model}) => {
      return post('/api/parameters', {
        label,
        cible_id,
        model
      })
    },
    remove: ({id}) => {
      return remove(`/api/parameters/${id}`);
    }
  },
  profile: {
    getAll: () => {
      return get('/api/profils');
    },
    get: ({id}) => {
      return get(`/api/profils/${id}`);
    },
    add: ({model, cible, label, description, permissions}) => {
      return post('/api/profils', {
        model,
        cible,
        label,
        description,
        permissions
      });
    },
    update: ({id, model, cible, label, description, permissions}) => {
      return put(`/api/profils/${id}`, {
        model,
        cible,
        label,
        description,
        permissions
      })
    }
  },
  taxonomies: {
    getAll: () => {
      return get('/api/taxonomies');
    },
    getGenerics: () => {
      return get('/api/taxonomies-generics');
    },
    get: ({id}) => {
      return get(`/api/taxonomies/${id}`);
    },
    add: ({client_id, parent_id, label, freeze}) => {
      return post('/api/taxonomies', {
        client_id,
        parent_id,
        label,
        freeze
      })
    },
    remove: ({id}) => {
      return remove(`/api/taxonomies/${id}`);
    },
    update: ({id, client_id, parent_id, label, freeze}) => {
      return put(`/api/taxonomies/${id}`, {
        client_id,
        parent_id,
        label,
        freeze
      })
    },
    clone: ({client_id, taxonomy_id}) => {
      return post('/api/taxonomies/use/create', {
        client_id,
        taxonomy_id
      })
    },
    copy: ({client_id, taxonomy_id}) => {
      return post('/api/taxonomies/use/create/unmarked', {
        client_id,
        taxonomy_id
      })
    }
  },
  themeQuestions: {
    add: ({theme_id, question_id, ranking}) => {
      return post('/api/themes-questions', {
        theme_id,
        question_id,
        ranking
      });
    },
    update: ({id, theme_id, question_id, ranking}) => {
      return put(`/api/themes-questions/${id}`, {
        theme_id,
        question_id,
        ranking
      });
    },
    remove: ({id}) => {
      return remove(`/api/themes-questions/${id}`);
    }
  },
  questions: {
    add: ({client_id, generic, label, title, faq, objectif, type, ranging, photo, answer, file, sample_comment, sample_value, sample_photos, sample_files}) => {
      return post('/api/questions', {
        client_id,
        generic,
        label,
        title,
        faq,
        objectif,
        type,
        ranging,
        photo,
        answer,
        file,
        sample_comment,
        sample_value,
        sample_photos,
        sample_files
      });
    },
    update: ({id, client_id, generic, label, title, faq, objectif, type, ranging, photo, answer, file, sample_comment, sample_value, sample_photos, sample_files}) => {
      return put(`/api/questions/${id}`, {
        client_id,
        generic,
        label,
        title,
        faq,
        objectif,
        type,
        ranging,
        photo,
        answer,
        file,
        sample_comment,
        sample_value,
        sample_photos,
        sample_files
      });
    },
    remove: ({id}) => {
      return remove(`/api/questions/${id}`);
    }
  },
  themes: {
    add: ({label, ranking, parent_id, client_id, generic}) => {
      return post('/api/themes', {
        label,
        ranking,
        client_id,
        parent_id,
        generic
      });
    },
    update: ({id, label, ranking, parent_id, client_id, generic}) => {
      return put(`/api/themes/${id}`, {
        id,
        label,
        ranking,
        parent_id,
        client_id,
        generic
      });
    },
    remove: ({id}) => {
      return remove(`/api/themes/${id}`);
    },
    copyTree: ({client_id, themes, themes_questions}) => {
      return post(`/api/clients/${client_id}/themes-clone`, {
        themes,
        themes_questions
      });
    },
    getGeneric: () => {
      return get('/api/themes-generic');
    }
  },
  brands: {
    getAll: () => {
      return get('/api/brands');
    },
    add: ({label, path}) => {
      return post('/api/brands', {
        label,
        path: (!!path && path.startsWith('data')) ? path.split('base64,')[1] : undefined
      });
    },
    remove: ({id}) => {
      return remove(`/api/brands/${id}`);
    },
    update: ({id, label, path}) => {
      return put(`/api/brands/${id}`, {
        label,
        path: (!!path && path.startsWith('data')) ? path.split('base64,')[1] : undefined
      });
    }
  },
  visit: {
    add: ({report_id, zone_exploitation_id, passage, status, user_id, comment}) => {
      return post(`/api/reports/${report_id}/zone-exploitations/${zone_exploitation_id}/rapports`, {
        passage: !!passage ? cDate.datePickerToSubmit(passage, 'd-m-Y') : undefined,
        status,
        user_id,
        comment
      });
    },
    update: ({id, passage, status, user_id, comment}) => {
      return put(`/api/rapports/${id}`, {
        passage: !!passage ? cDate.datePickerToSubmit(passage, 'd-m-Y') : undefined,
        status,
        user_id,
        comment
      })
    },
    validate: ({report_id, zone_exploitations_id}) => {
      return post(`/api/reports/${report_id}/zone-exploitations/${zone_exploitations_id}/rapports`, {
        status: 1
      });
    }
  },
  questionnaire: {
    add: ({audit_id, questionnaires}) => {
      return post(`/api/audits/${audit_id}/questionnaires`, questionnaires);
    },
    update: ({questionnaire_id, section_id, ranking, label, faq, title, type, ranging, objectif, photo, answer, file}) => {
      return put(`/api/questionnaires/${questionnaire_id}`, {
        label,
        faq,
        title,
        type,
        ranging,
        objectif,
        photo,
        answer,
        file,
        section_id,
        ranking
      });
    },
    updateMultiple: (questionnaires) => {
      return put('/api/questionnaires', questionnaires);
    },
    freeze: ({questionnaire_id, freeze}) => {
      return put(`/api/questionnaires/${questionnaire_id}/freeze/${!!freeze ? 'true' : 'false'}`);
    },
    getByReportAndZone: ({report_id, zone_exploitations_id}) => {
      return get(`/api/reports/${report_id}/zone-exploitations/${zone_exploitations_id}/questionnaires`);
    },
    sendResponse: ({questionnaire_id, zone_exploitation_id, report_id, value, files, photos, comment, valid}) => {
      return post('/api/response', {
        questionnaire_id,
        zone_exploitation_id,
        report_id,
        value,
        files,
        photos,
        comment,
        valid
      })
    }
  },
  section: {
    add: ({audit_id, ranking, label}) => {
      return post(`/api/audits/${audit_id}/sections`, {
        label,
        ranking,
        audit_id
      })
    },
    update: ({section_id, audit_id, label, ranking}) => {
      return put(`/api/sections/${section_id}`, {
        label,
        ranking,
        audit_id
      });
    },
    remove: ({id}) => {
      return remove(`/api/sections/${id}`);
    }
  },
  firms: {
    getAll: () => {
      return get('/api/firms');
    },
    get: ({id}) => {
      return get(`/api/firms/${id}`);
    },
    getUsers: ({firm_id}) => {
      return get(`/api/firms/${firm_id}/users`);
    },
    getUsersResponsables: ({firm_id}) => {
      return get(`/api/firms/${firm_id}/users?respo`);
    },
    add: ({label, path}) => {
      return post('/api/firms', {
        label,
        path
      });
    },
    update: ({id, label, path, clients, prestataires}) => {
      return put(`/api/firms/${id}`, {
        label,
        path,
        clients,
        prestataires
      });
    },
    remove: ({id}) => {
      return remove(`/api/firms/${id}`);
    }
  },
  file: {
    upload: ({data}) => {
      return post('/api/upload', {
        file: data
      });
    }
  },
  rapportsProcessing:{
    getProcessing: () => {
      return get(`/api/rapports-processing`);
    },
    forceProcessing: ({id, analyse_id, vague, zone_exploitation_id}) => {
      return post(`/api/rapports-force-processing`, {
        id,
        analyse_id,
        vague,
        zone_exploitation_id
      });
    },
  },
  analyse: {
    get: ({client_id}) => {
      return get(`/api/clients/${client_id}/analyses`);
    },
    add: ({label, client_id, users, sources, vagues, passage_low, passage_high}) => {
      return post(`/api/analyses`, {
        label,
        client_id,
        users,
        sources,
        vagues,
        passage_low,
        passage_high
      });
    },
    update: ({id, label, client_id, users, sources, vagues, passage_low, passage_high}) => {
      return put(`/api/analyses/${id}`, {
        label,
        client_id,
        users,
        sources,
        vagues,
        passage_low,
        passage_high
      });
    },
    remove: ({id}) => {
      return remove(`/api/analyses/${id}`).then(r => r).catch(e => ({ status: e.response.status, data: e.response.data}));
    }

  },
  constats: {
    fetch: ({data}) => {
      // data = [{
      //   vague,
      //   audit_id,
      //   question_id,
      //   zone_exploitation_id
      // }]
      return post('/api/constats', data)
    }
  },
  verifyAddress: ({address}) => {
    return post('/api/address', {
      address
    });
  }
};

export default api;
