import React from "react";
import {Link} from "react-router-dom";
import {I2CText} from "../TextRenderers";
import {Query} from "@jeremyglanum/forepaas-utils";
import {setOrmLabels} from "../../../tools/Filters/updateFunctions";
import {getColor} from "../../../../tools/colors";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import {DebounceInput, TopLoadingBar} from "@jeremyglanum/ui";
import {Button, Card, CardBody, CardHeader, Input} from "reactstrap";
import {defaultPaginationFactory} from "../../../../constants/ui";
import {Line} from "react-chartjs-2";
import {writeToExcel} from "../../../../tools/helperFunctions";
import _ from "lodash";
import {expandRendererProps} from "../../../tools/DatatableHelperFunctions";
import {rootThemeLabelSelector, setThemeRanking} from "./AnalyseView";
import {createSelector} from "../../../../tools/createSelector";
import {getRoute, Rep_MonSit} from "../../../../constants/modules";
const monoSiteUrl = getRoute(Rep_MonSit);

const analyseVaguesSelector = createSelector(
  (orm, {analyseId}) => {
    const analyse = orm.Analyse.withId(analyseId);
    const vagues = analyse.vagues;

    return vagues.map(v => ({
      vague: v.vague,
      label: v.label
    }));
  }
);

class AnalyseViewEvolutionTable extends React.PureComponent {
  constructor(props) {
    super(props);
    const {analyseId, vague} = this.props;

    this.state = {
      groupItems: [],
      groupItemsExpanded: [],
      displayType: 'table',
      loading: false
    };

    this.vagues = analyseVaguesSelector({analyseId});
    this.columns = this.getColumns(this.props.axisValue);
    this.groupColumns = this.getColumns(this.props.groupValue);
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  getColumns = (item, isExport) => {
    const {passageLow, passageHigh, client, analyseId, vague} = this.props;

    const vagues = this.vagues;

    let columns = [];
    let searchParams;

    columns.push({
      text: item.label,
      sort:true,
      dataField: 'label',
      formatter: (col, row) => {

        searchParams = new URLSearchParams();
        searchParams.append("zones_exploitation", row.id);
        if(analyseId) searchParams.append("analyses", analyseId);
        if(vague) searchParams.append("vagues", vague);

        if (isExport)
          return row.label;
        if (item.type === 'zoneExploitation')
          return <Link style={{color: 'black'}} to={{
            pathname: monoSiteUrl,
            search: searchParams.toString()
          }}>{row.label}</Link>;
        if (row.model === 'ThemeQuestion' && client.numerical)
          return <><b>{row.numericValue}</b> {row.label}</>;

        return row.label;
      }
    });
    if (!isExport)
      columns.push({
        hidden: true,
        text: '',
        dataField: 'ranking',
        headerAlign: 'center',
        align: 'center',
        sort: true
      });

    if (item.value === 'question' || item.value === 'subTheme') {
      columns.push({
        text: t('common.theme'),
        dataField: 'rootThemeLabel',
        headerAlign: 'center',
        align: 'center',
        sort: true
      });
      if (!isExport)
        columns.push({
          hidden: true,
          text: '',
          dataField: 'numericValueForSort',
          headerAlign: 'center',
          align: 'center',
          sort: true
        });
    }


    vagues.forEach((v, idx) => {
      columns.push({
        text: v.label,
        dataField: `vagues.${v.vague}`,
        headerAlign: 'center',
        align: 'center',
        sort:true,
        formatter: (col, row) => {
          const value = row.vagues[v.vague];

          if (isExport)
            return (value !== null && value !== undefined) ? Math.round(value) : 'N/C';

          return <b><I2CText value={value} passageHigh={passageHigh} passageLow={passageLow}/></b>
        }
      })
    });

    columns.push({
      text: t('reporting.Moyenne'),
      dataField: 'avgValue',
      headerAlign: 'center',
      align: 'center',
      sort:true,
      formatter: (col, row) => {
        if (isExport)
          return row.avgValue !== null ? Math.round(row.avgValue) : 'N/C';

        return <b><I2CText value={row.avgValue} passageHigh={passageHigh} passageLow={passageLow}/></b>
      }
    });

    return columns;
  };

  getGroupItemsList = async () => {
    const {analyseId} = this.props;
    const {groupValue, axisItemsFilterValue, axisValue} = this.props;

    const filter = !!axisItemsFilterValue && !!axisItemsFilterValue.length && groupValue.labelModel != 'ThemeQuestion' ? axisValue.fappFilter : groupValue.fappFilter;

    const query = new Query({
      query: {
        data: {
          fields: {
            value_origin: ['avg']
          }
        },
        scale: {
          fields: ['vague_analyse', 'analyse_id', groupValue.fappId]
        },
        filter: {
          analyse_id: [analyseId],
          ...filter,
          [axisValue.fappId]: !!axisItemsFilterValue && !!axisItemsFilterValue.length ? axisItemsFilterValue.map(i => i.value) : undefined
        },
        order: {
          vague_analyse: 'asc'
        },
        table_name: "ocm2_analyses_datas"
      }
    });

    const {results} = await query.execute();

    const items = {};

    results.forEach(r => {
      const data = r.data.value_origin.avg[0];
      const id = data[groupValue.fappId];
      const vague = data['vague_analyse'];
      const value = data.value;

      if (!items[id])
        items[id] = {
          id: id,
          value: id,
          vagues: {}
        };

      items[id].vagues[vague] = value;
    });

    const itemsList = Object.values(items);
    itemsList.forEach(i => {
      i.avgValue = getArrayAverage(Object.values(i.vagues));
    });

    setOrmLabels({
      modelName: groupValue.labelModel,
      items: itemsList
    });

    if (groupValue.value === 'theme')
      setThemeRanking({
        items: itemsList
      }, true);

    if (groupValue.value === 'question' || groupValue.value === 'subTheme')
      rootThemeLabelSelector({
        items: itemsList,
        model: groupValue.labelModel
      }, true);

    return itemsList;
  };

  getAxisItemsList = async (item) => {
    const {analyseId} = this.props;
    const {axisValue, groupValue, axisItemsFilterValue} = this.props;

    const fappFilterValue = groupValue.type === 'questionnaire' ? groupValue.fappFilter : axisValue.fappFilter;

    const query = new Query({
      query: {
        data: {
          fields: {
            value_origin: ['avg']
          }
        },
        scale: {
          fields: ['vague_analyse', 'analyse_id', axisValue.fappId]
        },
        filter: {
          analyse_id: [analyseId],
          [groupValue.fappId]: [item?.id],
          ...fappFilterValue,
          [axisValue.fappId]: !!axisItemsFilterValue && !!axisItemsFilterValue.length ? axisItemsFilterValue.map(i => i.value) : undefined
        },
        order: {
          vague_analyse: 'asc'
        },
        table_name: "ocm2_analyses_datas"
      }
    });

    const {results} = await query.execute();

    const items = {};

    results.forEach(r => {
      const data = r.data.value_origin.avg[0];
      const id = data[axisValue.fappId];
      const vague = data['vague_analyse'];
      const value = data.value;

      if (!items[id])
        items[id] = {
          id: id,
          vagues: {}
        };

      items[id].vagues[vague] = value;
    });

    const itemsList = Object.values(items);
    itemsList.forEach(i => {
      i.avgValue = getArrayAverage(Object.values(i.vagues));
    });

    setOrmLabels({
      modelName: axisValue.labelModel,
      items: itemsList
    });

    if (axisValue.value === 'theme')
      setThemeRanking({
        items: itemsList
      }, true);

    if (axisValue.value === 'question' || axisValue.value === 'subTheme')
      rootThemeLabelSelector({
        items: itemsList,
        model: axisValue.labelModel
      }, true);

    return itemsList;
  };

  getAxisOptions = (itemType) => {
    const options = this.groupOptions.filter(i => i.type !== itemType);

    return options;
  };

  setData = async () => {
    const {clientId, analyseId} = this.props;
    const {groupValue, axisValue} = this.props;

    this.setState({loading: true});
    const items = await this.getGroupItemsList();

    this.vagues = analyseVaguesSelector({analyseId});
    this.columns = this.getColumns(axisValue);
    this.groupColumns = this.getColumns(groupValue);
    this.setState({
      groupItems: items,
      groupItemsExpanded: [],
      loading: false
    });
  };

  componentDidMount(){
    this.setData();
  }

  componentDidUpdate(prevProps, prevState){
    const {groupValue, axisValue, analyseId, axisItemsFilterValue} = this.props;

    if (groupValue !== prevProps.groupValue ||
      axisValue !== prevProps.axisValue ||
      analyseId !== prevProps.analyseId ||
      axisItemsFilterValue !== prevProps.axisItemsFilterValue){
      this.setData();
    }
  }

  getGraphicDatasets = (items) => {
    const vagues = this.vagues;
    const {axisValue} = this.props;

    let data = {
      labels: vagues.map(v => v.label),
      datasets: items.map(i => {
        const color = getColor(axisValue.labelModel, i.id);

        return {
          label: i.label,
          data: vagues.map(v => i.vagues[v.vague]),
          fill: false,
          borderColor: color,
          backgroundColor: color
        }
      })
    };

    return data;
  };

  graphicOptions = {
    tooltips: {
      callbacks: {
        label: (item, data) => {
          const dataset = data.datasets[item.datasetIndex];
          return `${dataset.label} : ${dataset.data[item.index].toFixed(0)}%`;
        }
      }
    },
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    scales: {
      yAxes: [{
        ticks: {
          suggestedMax: 100,
          beginAtZero: true
        }
      }]
    }
  };

  printTable = (items) => {
    const {axisValue} = this.props;

    return (
      <ToolkitProvider
        search
        keyField={'id'}
        columns={this.columns}
        bootstrap4={true}
        data={items}>
        {props => (
          <div>
            <div class="mb-2 d-flex">
              <DebounceInput
                className="mr-2 flex-grow-1"
                groupProps={{
                  size: 'sm'
                }}
                onChange={(e) => props.searchProps.onSearch(e.target.value)}/>
              <Button
                size="sm"
                title="Exporter en excel"
                onClick={() => this.handleAxisItemsExport(items)}
                color="success">
                <i className="far fa-file-excel"/>
                &nbsp;
                {t('common.export')}
              </Button>
            </div>
            <BootstrapTable
              defaultSorted={[{
                dataField: axisValue.sortField,
                order: 'asc'
              }]}
              bordered={false}
              condensed={true}
              noDataIndication={() => t('common.no_data')}
              pagination={defaultPaginationFactory()}
              {...props.baseProps}/>
          </div>
        )}
      </ToolkitProvider>
    )
  };

  printChart = (data) => {
    let options = {
      ...this.graphicOptions
    };

    if (data.datasets.length < 13)
      options.legend.display = true;

    return (
      <div style={{height: 300}}>
        <Line
          data={data}
          options={options}/>
      </div>
    )
  };

  expandRowRenderer = row => {
    const {displayType} = this.state;

    if (!!row.loading)
      return <i className="fa fa-circle-notch fa-spin text-primary"/>;

    if (row.loaded){
      return (
        <div style={{paddingLeft: 30}}>
          {displayType === 'table' ?
            this.printTable(row.children) :
            this.printChart(row.chartData)}
        </div>
      );
    }
  };

  handleRowExpand = async (row, isExpand) => {
    const {groupItems, groupItemsExpanded} = this.state;

    if (!!isExpand && !row.loaded){
      row.loading = true;

      const items = await this.getAxisItemsList(row);

      this.setState({
        groupItems: groupItems.map(i => {
          if (i.id === row.id)
            return {
              ...i,
              loading: false,
              loaded: true,
              children: items,
              chartData: this.getGraphicDatasets(items)
            };
          return i;
        })
      })
    }

    this.setState({
      groupItemsExpanded: !!isExpand ? [...groupItemsExpanded, row.id] : groupItemsExpanded.filter(id => id !== row.id)
    });
  };

  handleAxisItemsExport = (items) => {
    const {axisValue} = this.props;

    const columns = this.getColumns(axisValue, true);

    writeToExcel({
      name: `Export ${moment().format('YYYY-MM-DD HH:mm:ss')}`,
      columns: columns,
      data: _.sortBy(items, 'label')
    });
  };

  handleGroupItemsExport = () => {
    const {groupItems} = this.state;
    const {groupValue} = this.props;

    const columns = this.getColumns(groupValue, true);

    writeToExcel({
      name: `Export ${moment().format('YYYY-MM-DD HH:mm:ss')}`,
      columns: columns,
      data: _.sortBy(groupItems, 'label')
    });
  };

  render(){
    const {
      groupItems,
      displayType,
      groupItemsExpanded,
      loading,
    } = this.state;
    const {groupValue} = this.props;

    return (
      <Card>
        <TopLoadingBar isLoading={loading}/>
        <CardHeader class="d-flex align-items-center">
          <span class="flex-grow-1">
            {t("reporting.Evolution de i2c par axe de comparaison", {I2C: Labels.i2c})}
          </span>
          <Input
            bsSize="sm"
            type="select"
            name="displayType"
            value={displayType}
            style={{width: 150}}
            onChange={this.handleChange}>
            <option value="table">{t('common.array')}</option>
            <option value="chart">{t('reporting.graphic')}</option>
          </Input>
        </CardHeader>
        <CardBody>
          {!loading &&
          <ToolkitProvider
            search
            keyField={'id'}
            columns={this.groupColumns}
            bootstrap4={true}
            data={groupItems}>
            {props => (
              <div>
                <div className="mb-2 d-flex">
                  <DebounceInput
                    className="mr-2 flex-grow-1"
                    onChange={(e) => props.searchProps.onSearch(e.target.value)}/>
                  <Button
                    title="Exporter en excel"
                    onClick={this.handleGroupItemsExport}
                    color="success">
                    <i className="far fa-file-excel"/>
                    &nbsp;
                    {t('common.export')}
                  </Button>
                </div>
                <BootstrapTable
                  expandRow={{
                    onExpandAll: () => null,
                    onExpand: this.handleRowExpand,
                    expanded: groupItemsExpanded,
                    renderer: this.expandRowRenderer,
                    showExpandColumn: true,
                    expandByColumnOnly: true,
                    ...expandRendererProps,
                    expandHeaderColumnRenderer: () => null
                  }}
                  defaultSorted={[{
                    dataField: groupValue.sortField,
                    order: 'asc'
                  }]}
                  bordered={false}
                  condensed={true}
                  noDataIndication={() => t('common.no_data')}
                  pagination={defaultPaginationFactory()}
                  {...props.baseProps}/>
              </div>
            )}
          </ToolkitProvider>}
        </CardBody>
      </Card>
    )
  }
}

export {AnalyseViewEvolutionTable};
export default AnalyseViewEvolutionTable;
