import React from "react";
import {Row, Col, Label, Nav, NavItem, FormGroup} from "reactstrap";
import {NavLink} from "react-router-dom";
import {Redirect, Route} from "react-router";
import {SyntheticView} from "./SyntheticView";
import {ZonesExploitationView} from "./ZonesExploitationView";
import {ThemesView} from "./ThemesView";
import {AnalyseView} from "./AnalyseView/AnalyseView";
import {TotalI2CHeader} from "./TotalI2CHeader";
import {connect} from "react-redux";
import {fetchClientAnalyses} from "../../../actions/orm/Analyse";
import {createClientSelectorById} from "../../selectors/clients";
import {createAnalyseByIdSelector} from "../../selectors/analyse";
import _ from "lodash";
import {startPending, stopPending} from "../../../actions/ui";
import {TaxonomyFilters} from "./TaxonomyFilters";
import {
  fetchClientOrganisation,
  fetchClientTaxonomies,
  fetchClientThemesTree,
  fetchClientAudits
} from "../../../actions/orm/Client";
import qs from "query-string";
import {filtersState} from "./filtersState";
import SyntheticProgressPlanView from "./SyntheticProgressPlanView/SyntheticProgressPlanView";
import {withFilters, FiltersContextProvider, Filter, TreeFilter} from "@jeremyglanum/filters";
import {fetchPrestataires} from "../../../actions/orm/Prestataire";

const clientSelector = createClientSelectorById();
const analyseSelector = createAnalyseByIdSelector();

@withFilters(({analyses: {value}}) => ({
  analyse: analyseSelector({id: !!value[0] ? value[0].value : null})
}))
@connect(({session: {currentClientId}}) => ({
  clientId: currentClientId,
  client: clientSelector({id: currentClientId})
}))
class Reporting extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      init: false
    };
  }

  themeViewFilters = [
    "clients",
    "analyses",
    "vagues",
    "zones_exploitation",
    "prestataires"
  ];

  async componentDidMount(){
    this.props.dispatch(startPending());
    await Promise.all([
      this.props.dispatch(fetchClientAudits({client_id: this.props.clientId})),
      this.props.dispatch(fetchClientOrganisation({client_id: this.props.clientId})),
      this.props.dispatch(fetchClientTaxonomies({client_id: this.props.clientId})),
      this.props.dispatch(fetchClientAnalyses({client_id: this.props.clientId})),
      this.props.dispatch(fetchClientThemesTree({client_id: this.props.clientId})),
      this.props.dispatch(fetchPrestataires())
    ]);
    this.props.dispatch(stopPending());
    this.setState({init: true});
  };

  render(){
    const {init} = this.state;
    const {match: {params: {section}}, client, analyse} = this.props;
    const zoneLabel = client.label_zone_exploitation;

    const passageLow = !!analyse ? analyse.passage_low : 70;
    const passageHigh = !!analyse ? analyse.passage_high : 90;

    if (!init)
      return null;
    if (!section)
      return <Redirect to={'/reporting/multi/synthetic'}/>;

    return (
      <div className="page-inner">
        <div className="page-section">
          {!analyse &&
          <div>
            <h6>Vous n'avez accès à aucun rapport.</h6>
          </div>}
          <Row>
            <Col sm={2} className={!analyse ? "d-none" : ""}>
              <div>
                <Label><strong>{t('common.prestataires')} :</strong></Label>
                <Filter id="prestataires"
                        placeholder={`${t('common.Sélectionner')}...`}/>
                <br/>
                <Label><strong>{t('common.analyses')} :</strong></Label>
                <Filter placeholder={`${t('common.Sélectionner')}...`}
                        id="analyses"/>
                <br/>
                <Label><strong>{t('common.vagues')} :</strong></Label>
                <Filter placeholder={`${t('common.Sélectionner')}...`}
                        id="vagues"/>
                <TreeFilter id="taxonomiesTree">
                  {(filter, onChange, onCheck, handleTreeChange) => {
                    const {value, options} = filter;

                    if (!options || !options.length)
                      return <React.Fragment/>;

                    return (
                      <>
                        <br/>
                        <Label><strong>{t('common.taxonomies')} :</strong></Label>
                        <TaxonomyFilters
                          value={value}
                          options={options}
                          onCheck={onCheck}
                          handleTreeChange={handleTreeChange}/>
                      </>
                    );
                  }}
                </TreeFilter>
                <br/>
                <Label><strong>{zoneLabel} :</strong></Label>
                <Filter placeholder={`${t('common.Sélectionner')}...`}
                        id="zones_exploitation"/>
              </div>
            </Col>
            {!!analyse &&
            <Col sm={10}>
              <div className="nav-scroller border-bottom mb-3 flex-shrink-0">
                <Nav tabs className="navigation">
                  <NavItem>
                    <NavLink
                      exact
                      className={'nav-link'}
                      to={`/reporting/multi/synthetic`}>
                      {t('reporting.synthetic_results')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={'nav-link'}
                      to={`/reporting/multi/themes`}>
                      {t('reporting.thematic_results')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={'nav-link'}
                      to={`/reporting/multi/zones`}>
                      {t('reporting.results_per')} {client.label_zone_exploitation}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={'nav-link'}
                      to={`/reporting/multi/analyse`}>
                      {t('reporting.Analyse comparative')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={'nav-link'}
                      to={`/reporting/multi/syntheticpp`}>
                      {t('reporting.synthesis_pp')}
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <div class="mb-4">
                <TotalI2CHeader
                  passageLow={passageLow}
                  passageHigh={passageHigh}
                  zoneLabel={client.label_zone_exploitation}/>
              </div>
              <div className="containerscroll">
                <Route path={'/reporting/multi/synthetic'}
                      render={(props) => <SyntheticView {...props} passageLow={passageLow} passageHigh={passageHigh}/>}/>
                <Route path={'/reporting/multi/themes'}
                      render={(props) => <ThemesView
                        {...props}
                        passageLow={passageLow}
                        dynamicFilters={this.themeViewFilters}
                        passageHigh={passageHigh}/>}/>
                <Route path={'/reporting/multi/zones'}
                      render={(props) => <ZonesExploitationView {...props} passageLow={passageLow} passageHigh={passageHigh}/>}/>
                <Route path={'/reporting/multi/analyse'}
                      render={(props) => <AnalyseView {...props} passageLow={passageLow} passageHigh={passageHigh}/>}/>
                <Route path={`/reporting/multi/syntheticpp`}
                      render={(props) => <SyntheticProgressPlanView {...props} passageLow={passageLow} passageHigh={passageHigh}/>}/>
              </div>
            </Col>}
          </Row>
        </div>
      </div>
    )
  }
}

@connect(({session: {currentClientId}}) => ({
  clientId: currentClientId
}))
class Index extends React.PureComponent {
  constructor(props) {
    super(props);

    this.filters = _.cloneDeep(filtersState);

    const {location} = this.props;
    const queryParams = qs.parse(location.search);

    this.filters.clients.value[0].value = this.props.clientId;

    Object.keys(queryParams).forEach(key => {
      const value = queryParams[key];

      if (!!this.filters[key]){
        this.filters[key].value = value.map(i => ({value: parseInt(i)}));
      }
    });
  }

  render(){
    return (
      <FiltersContextProvider filters={this.filters}>
        <Reporting {...this.props}/>
      </FiltersContextProvider>
    )
  }
}

export {Index};
export default Index;
