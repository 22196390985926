import React from "react";
import {connect} from "react-redux";
import {
  FormGroup,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Badge
} from "reactstrap";
import {setCopyRootClientsProgressModels} from "../../actions/modals";
import LoadingButton from "../common/LoadingButton";
import createSelector from "../../tools/createSelector";
import {fetchProgressModels} from "../../actions/orm/ProgressModel";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {DebounceInput} from "@jeremyglanum/ui";
import BootstrapTable from "react-bootstrap-table-next";
import {defaultPaginationFactory} from "../../constants/ui";
import {addProgressModel} from "../../actions/orm/ProgressModel";
import {OrganisationIconPlaceholder} from "../common/ImagePlaceholders";

const rootClientsSelector = createSelector(
  (orm) => {
    return orm.Client.all().filter(i => !i.parent_id && !!i.scope).toRefArray()
  }
);

const progressModelsSelectorByClientIds = createSelector(
  (orm, {clientIds}) => {

    return clientIds.map(clientId => {
        const client = orm.Client.withId(clientId);
        const progressModels = client.progressModels.toModelArray();

        return progressModels.map(model => {
          return {
            ...model.ref,
            client: model.client_id.ref,
            actions: model.modelActions.toRefArray(),
            themes: model.themes.toRefArray(),
            themes_questions: model.themes_questions.toRefArray()
          }
        });
      })
      .flat()
  }
);

@connect(({modals: {copyRootClientsProgressModels: {targetClientId}}}) => ({
  targetClientId
}))
class CopyRootClientsProgressModels extends React.Component {
  state = {
    loading: false,
    closing: false,
    models: []
  };

  tableRef = null;

  columns = [
    {
      dataField: 'label',
      text: t('common.label'),
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return (
          <span>
            <Badge color={"info"}>
               {!!row.themes.length ? t('common.theme_badge') : t('common.question_badge')}
            </Badge>
            &nbsp;
            {row.label}
          </span>
        )
      }
    },
    {
      dataField: 'client',
      text: t('common.client'),
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return (
          <span>
            <OrganisationIconPlaceholder src={row.client.path} size={20}/>
            &nbsp;&nbsp;
            {row.client.label}
          </span>
        )
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === 'asc')
          return rowA.client.label.localeCompare(rowB.client.label);
        return rowB.client.label.localeCompare(rowA.client.label);
      }
    },
    {
      dataField: 'level',
      text: t('common.priority'),
      sort: true
    },
    {
      dataField: 'themes',
      text: t('common.themes_questions'),
      formatter: (cell, row, rowIndex) => {
        return (
          <span>
            {!!row.themes.length ?
              row.themes.map(i => i.label).join(', ') :
              row.themes_questions.map(i => !!i.title ? i.title : i.label).join(', ')
            }
          </span>
        )
      }
    }
  ];

  async componentDidMount(){
    const rootClients = rootClientsSelector({});

    await this.props.dispatch(fetchProgressModels({
      clients: rootClients.map(i => i.id)
    }));

    const models = progressModelsSelectorByClientIds({clientIds: rootClients.map(i => i.id)});
    this.setState({models});
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {targetClientId} = this.props;

    const selectedModels = this.tableRef.selectionContext.state.selected
      .map(id => this.state.models.find(i => i.id === id));

    const calls = selectedModels.map(model => {
      return this.props.dispatch(addProgressModel({
        client_id: targetClientId,
        label: model.label,
        level: model.level,
        actions: model.actions.map(a => a.label),
        themes_questions: model.themes_questions.map(t => t.id),
        themes: model.themes.map(t => t.id)
      }));
    });

    this.setState({loading: true});
    await Promise.all(calls);
    this.setState({loading: false});
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setCopyRootClientsProgressModels({
      open: false
    }));
  };

  render(){
    const {closing, loading, models} = this.state;

    return (
      <Modal
        isOpen={!closing}
        size="lg"
        onClosed={this.close}>
        <ModalHeader toggle={this.closing}>
          {t('modals.copy_pp_models')}
        </ModalHeader>
        <ModalBody>
          <p className="text-muted">
            {t('modals.select_models_to_copy')}
          </p>
          <FormGroup>
            <ToolkitProvider
              search
              keyField={'id'}
              columns={this.columns}
              bootstrap4={true}
              data={models}>
              {props => (
                <div>
                  <DebounceInput className="mb-2" onChange={(e) => props.searchProps.onSearch(e.target.value)}/>
                  <BootstrapTable
                    ref={ref => this.tableRef = ref}
                    bordered={false}
                    noDataIndication={() => t('common.no_data')}
                    rowStyle={{cursor: 'pointer'}}
                    selectRow={{
                      mode: 'checkbox',
                      clickToSelect: true,
                      bgColor: "#e6e6ed",
                      selectionRenderer: ({ mode, checked, disabled }) => (
                        <div className="cursor-pointer custom-control custom-control-nolabel custom-checkbox">
                          <input readOnly type="checkbox" className="custom-control-input" checked={checked}/>
                          <label className="cursor-pointer custom-control-label"/>
                        </div>
                      ),
                      selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
                        <div className="cursor-pointer custom-control custom-control-nolabel custom-checkbox">
                          <input readOnly type="checkbox" className="custom-control-input" checked={checked}/>
                          <label className="cursor-pointer custom-control-label"/>
                        </div>
                      )
                    }}
                    pagination={defaultPaginationFactory()}
                    {...props.baseProps}/>
                </div>
              )}
            </ToolkitProvider>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            type='button'
            disabled={loading}
            onClick={this.closing}>
            {t('common.close')}
          </Button>
          <LoadingButton
            loading={loading}
            disabled={loading}
            onClick={this.handleSubmit}
            color="success">
            {t('common.valid')}
          </LoadingButton>
        </ModalFooter>
      </Modal>
    )
  }
}

export {CopyRootClientsProgressModels};
export default CopyRootClientsProgressModels;
