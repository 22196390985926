import React from "react";
import {Col, Label, Row} from "reactstrap";
import {SingleValueI2CDoughnut} from "./SingleValueI2CDoughnut";
import {Red,Orange} from "./utils";
import {EvolText} from "./TextRenderers";
import {createItemI2cFetch} from "../../tools/Filters/updateFunctions";
import {FRequest, withFilters} from "@jeremyglanum/filters";
import {createAnalyseByIdSelector} from "../../selectors/analyse";

const analyseSelector = createAnalyseByIdSelector();

@withFilters(({analyses: {value}}) => ({
  analyse: analyseSelector({id: !!value[0] ? value[0].value : null})
}))

class TotalI2CHeader extends React.PureComponent {
  updateFunc = createItemI2cFetch({
    params: {
      lastVagueValue: true,
      lastVagueDiff: true,
      zonesCount: true
    },
    level: [1],
    by: 'vague'
  });

  render(){
    const {passageLow, passageHigh, zoneLabel} = this.props;

    return (
      <Row>
        <Col>
          <Label>
            <strong>
              {t('common.seuil')}
            </strong>
          </Label>
          <div>
            <i className='fa fa-circle' style={{color: '#57a806'}} />
            &nbsp;
            {t('reporting.up_or_equal')} {passageHigh}%
          </div>
          <div>
            <i className='fa fa-circle' style={{color: Orange}} />
            &nbsp;
            {t('reporting.between')} {passageLow}% {t('reporting.and')} {passageHigh}%
          </div>
          <div>
            <i className='fa fa-circle' style={{color: Red}} />
            &nbsp;
            {t('reporting.less_than')} {passageLow}%
          </div>
        </Col>
        <Col class="d-flex flex-column align-items-center">
          <Label>
            <strong>
              {Labels.i2c}
            </strong>
          </Label>
          <FRequest
            dynamicParameters={[
              "analyses",
              "vagues",
              "zones_exploitation"
            ]}
            updateFunc={this.updateFunc}>
            {(result, loading, error) => {

              if (loading && !result)
                return <div class="text-center">{t('reporting.loading')}</div>;

              if (!result || !result.length)
                return <div class="text-center">{t('reporting.no_data')}</div>;
              
              const data = result[0] || {};

              const sameVague = data.vague === 1;
              const currentVagueI2C = data.value;
              const lastVagueI2C = data.lastVagueValue;
              const diff = data.lastVagueDiff;

              // Récupération date vague précédente
              const vagueNb = data.vague > 1 ? data.vague - 1 : data.vague;
              const vagueLabel = !!this.props.analyse.vagues && !!this.props.analyse.vagues.length ? 
                !!this.props.analyse.vagues.find(a => a.vague === vagueNb) 
                ? this.props.analyse.vagues.find(a => a.vague === vagueNb).label
                : this.props.analyse.vagues[0].label
                : null;

              // Récupération date vague actuelle
              const vagueLabel_actuel = !!this.props.analyse.vagues && !!this.props.analyse.vagues.length ? 
                !!this.props.analyse.vagues.find(a => a.vague === data.vague) 
                ? this.props.analyse.vagues.find(a => a.vague === data.vague).label
                : this.props.analyse.vagues[0].label
                : null;

              return (
                <div className="d-flex flex-grow-1 w-100">
                  {!sameVague &&
                  <div style={{width: 100}} className="flex-grow-1 d-flex align-items-center justify-content-center">
                    <div className="text-center d-flex flex-column align-items-center">
                      <span className="text-muted">{t('reporting.i2c_rappel', {I2C: Labels.i2c})}<br/>{t('reporting.previous_vague')}</span>
                      
                      <span className="text-center">{vagueLabel}</span>

                      <SingleValueI2CDoughnut
                        size={60}
                        fontSize={15}
                        value={lastVagueI2C}
                        passageLow={passageLow}
                        passageHigh={passageHigh}/>
                    </div>
                  </div>}
                  <div style={{width: 100}} className="flex-grow-1 d-flex align-items-center justify-content-center">
                  
                    <div className="text-center d-flex flex-column align-items-center">

                      <span className="text-center">{vagueLabel_actuel}</span>

                      <SingleValueI2CDoughnut
                        size={120}
                        value={currentVagueI2C}
                        passageLow={passageLow}
                        passageHigh={passageHigh}/>
                    
                    </div>

                  </div>
                  {!sameVague &&
                  <div style={{width: 100}} className="flex-grow-1 d-flex align-items-center justify-content-center">
                    <h2 className="m-0"><EvolText value={diff}/></h2>
                  </div>}
                </div>
              )
            }}
          </FRequest>
        </Col>
        <Col class="d-flex flex-column align-items-center">
          <Label>
            <strong>
              {t('reporting.number_of')} {zoneLabel}
            </strong>
          </Label>
          <FRequest
            dynamicParameters={[
              "analyses",
              "vagues",
              "zones_exploitation"
            ]}
            updateFunc={this.updateFunc}>
            {(result, loading, error) => {
              return (
                <div class="flex-grow-1 d-flex align-items-center justify-content-center">
                  {loading && !result &&  t('reporting.loading')}
                  {!loading && result &&
                  <h1 class="m-0">{!!result.length ? result[0].zonesCount : 0}</h1>}
                </div>
              )}}
          </FRequest>
        </Col>
      </Row>
    )
  }
}

export {TotalI2CHeader};
export default TotalI2CHeader;
