import React from "react";
import {connect} from "react-redux";
import {
  Form,
  FormGroup,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback, Nav, NavItem, NavLink
} from "reactstrap";
import {FormValidator} from "../../tools/formValidator/FormValidator";
import {setSetupNewSiteObject, setTaxonomyItemSelection} from "../../actions/modals";
import {allClientPrestataires} from "../selectors/prestataires";
import {Select2Wrapper} from "../common/Select2Wrapper";
import {createSelectedTaxonomiesListSelector} from "../selectors/taxonomy";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {WeekTimetable} from "../common/WeekTimetable";
import {createAllBrandsSelector} from "../selectors/brand";
import {fetchPrestataireUsers} from "../../actions/orm/Prestataire";
import createSelector from "../../tools/createSelector";
import {tFunction} from "../tools/Translation";
import {DebounceInput} from "@jeremyglanum/ui";
import {createClientSelectorById} from "../selectors/clients";
import update from "immutability-helper";

const localFv = new FormValidator([
  {
    field: 'label',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('modals.label_specified')
  },
  {
    field: 'adresse',
    method: (value, state) => {
      if (!value.length)
        return true;
      else if (state.adresseValidated)
        return true;
      return false;
    },
    validWhen: true,
    message: tFunction('modals.address_required')
  },
  {
    field: 'zLabel',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('modals.label_specified')
  }
]);

const selectedTaxonomiesListSelector = createSelectedTaxonomiesListSelector();
const brandsSelector = createAllBrandsSelector();

const responsablesByPrestataireIdSelector = createSelector(
  (orm, {prestataireId}) => {
    const prestataire = orm.Prestataire.withId(prestataireId);

    return prestataire.users.toRefArray().map(u => ({
      id: u.id,
      text: `${u.firstname} ${u.lastname}`
    }))
  }
);

const PrestataireUsersSelector = createSelector(
  (orm, {client_id}) => {
    const client = orm.Client.withId(client_id);
    const clientPrestataires = client.prestataires.toModelArray();
    const responsables = {};

    clientPrestataires.forEach(p => {
      responsables[p.id] = _.orderBy(p.users.toRefArray().map(u => {
        return {
          id: u.id,
          text: `${u.firstname} ${u.lastname}`
        }
      }), ['text'], 'asc');
    });
    return responsables;
  }
);

const clientSelector = createClientSelectorById();

@connect(({modals: {setupNewSiteObject : {resolve, reject, client_id}}}) => ({
  resolve,
  reject,
  client_id,
  responsables: PrestataireUsersSelector({client_id}),
  client: clientSelector({id: client_id})
}))
class SetupNewSiteObject extends React.Component {
  state = {
    closing: false,
    label: '',
    adresse: '',
    zLabel: '',
    adresseValidated: false,
    latitude: 0,
    longitude: 0,
    selectedPrestataires: '',
    selectedResponsables: [],
    mainResponsableId: '',
    responsableChoices: [],
    brands: brandsSelector({}),
    brand_id: '',
    prestataires: allClientPrestataires({clientId: this.props.client_id}),
    schedules: [],
    taxonomies: [],
    comment: '',
    zone_characteristics: this.props.client.characteristics.map(ch => ({
      characteristic_id: ch.id,
      label: '',
      name: ch.label
    })),
    validation: localFv.valid(),
    mode: "general"
  };
  addressInputRef = null;
  inputLabel = React.createRef();

  handleCharacteristicContentChange = (idx, label) => {
    this.setState(update(this.state, {
      zone_characteristics: {
        [idx]: {
          label: {$set: label}
        }
      }
    }))
  };

  handleAddressChange = (e) => {
    this.setState({
      [e.target.name] : e.target.value,
      adresseValidated: false
    });
  };

  setAddressGoogleListener = () => {
    const options = {
      types: ['address']
    };

    const autocomplete = new google.maps.places.Autocomplete(this.addressInputRef, options);
    autocomplete.setFields(['formatted_address', 'geometry']);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      const {formatted_address, geometry} = place;

      this.setState({
        adresse: formatted_address,
        adresseValidated: true,
        latitude: geometry.location.lat(),
        longitude: geometry.location.lng()
      });
    });
  };

  loadData = async () => {
    await Promise.all(allClientPrestataires({clientId: this.props.client_id}).map(item => {
      this.props.dispatch(fetchPrestataireUsers({prestataire_id: item.id}));
    }));
  };

  async componentDidMount(){
    const {client_id} = this.props;

    const tList = selectedTaxonomiesListSelector({
      clientId: client_id,
      selectedIds: []
    }).map((item, idx) => ({
      ...item,
      tId: idx
    }));

    await this.loadData();

    this.inputLabel.focus();
    this.setState({taxonomies: tList});
    this.setAddressGoogleListener();
  }

  handleSchedulesChange = (value) => {
    this.setState({schedules: value});
  };

  setMode = (mode) => {
    this.setState({mode});
  };

  onChange = (e) => {
    this.setState({[e.target.name] : e.target.value});
  };

  onChangeResponsables = (e) => {
    const {mainResponsableId} = this.state;

    this.setState({
      selectedResponsables: e.target.value,
      mainResponsableId: !e.target.value.length ? '' : e.target.value.includes(mainResponsableId) ? mainResponsableId : e.target.value[0]
    })
  };

  onPrestatairesChange = (e) => {
    const {target: {name, value}} = e;

    if (!value){
      this.setState({
        [name]: value,
        responsablesChoices: [],
        selectedResponsables: [],
        mainResponsableId: ''
      });
    }else {
      const responsables = responsablesByPrestataireIdSelector({prestataireId: value});

      this.setState({
        [name]: value,
        responsableChoices: responsables,
        selectedResponsables: [],
        mainResponsableId: ''
      });
    }
  };

  resolve = (e) => {
    const {label, brand_id, schedules, taxonomies, comment, zLabel, adresse, selectedPrestataires, adresseValidated, latitude, longitude, selectedResponsables, mainResponsableId, zone_characteristics} = this.state;

    e.preventDefault();
    const validations = localFv.validate({
      label,
      zLabel,
      adresse,
      adresseValidated: true
    });
    if (!validations.isValid) {
      this.setState({validation: validations});
      return;
    }

    const t = taxonomies
      .map(item => item.leaf)
      .filter(i => !!i)
      .map(i => i.id);

    this.props.resolve({
      label,
      adresse,
      latitude,
      longitude,
      type: 'site',
      children: [
        {
          label: zLabel,
          responsable_ids: !!selectedResponsables.length ? selectedResponsables : [],
          main_responsable_id: !!mainResponsableId && !!mainResponsableId.length ? mainResponsableId : null,
          prestataires: !!selectedPrestataires ? [selectedPrestataires] : [],
          taxonomies: t,
          comment,
          schedules,
          brand_id,
          zone_characteristics,
          type: 'zone_exploitations'
        }
      ]
    });
    this.closing();
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setSetupNewSiteObject({
      open: false
    }));
  };

  columns = [
    {
      dataField: 'root',
      text: t('common.name'),
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return row.root.label;
      },
      sortFunc: (a,b,order,dataField,rowA,rowB) => {
        if (order === 'asc')
          return rowA.root.label.localeCompare(rowB.root.label);
        return rowB.root.label.localeCompare(rowA.root.label);
      },
      filterValue: (cell, row) => row.root.label
    },
    {
      dataField: 'leaf',
      text: t('common.applied'),
      formatter: (cell, row, rowIndex) => {
        if (!!row.leaf)
          return row.leaf.label;
        return t('common.not_applied');
      }
    },
    {
      dataField: '',
      text: '',
      isDummyField: true,
      headerStyle: {
        width: 55
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <Button
            size="sm"
            onClick={() => this.chooseTaxonomy(row.root.id, !!row.leaf ? row.leaf.id : null)}
            title={t('modals.choose_taxonomy')}>
            <i className="fa fa-edit"/>
          </Button>
        )
      }
    }
  ];

  chooseTaxonomy = async (rootId, defaultSelectedId) => {
    try {
      const t = await this.props.dispatch(setTaxonomyItemSelection({
        open: true,
        taxonomyId: rootId,
        defaultSelectedId: defaultSelectedId
      }));
      const newTaxonomies = this.state.taxonomies.map(item => {
        if (item.root.id === rootId)
          return {
            ...item,
            leaf: t
          };
        return item;
      });

      this.setState({taxonomies: newTaxonomies});
    } catch (e) {}
  };

  render(){
    const {
      closing,
      label,
      zLabel,
      adresse,
      selectedPrestataires,
      selectedResponsables,
      mainResponsableId,
      responsableChoices,
      prestataires,
      taxonomies,
      comment,
      schedules,
      validation,
      brand_id,
      brands,
      mode,
      zone_characteristics
    } = this.state;
    const prestatairesChoices = prestataires.map(i => ({
      text: i.label,
      id: i.id
    }));

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {t('common.create_site')}
        </ModalHeader>
        <Form onSubmit={this.resolve}>
          <ModalBody>
            <FormGroup>
              <Label>
                {t('common.label')}
              </Label>
              <Input
                name="label"
                innerRef={(input) => (this.inputLabel = input)}
                invalid={validation.label.isInvalid}
                placeholder={t('common.label')}
                onChange={this.onChange}
                value={label}/>
              <FormFeedback>
                {validation.label.message}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label>
                {t('common.address')}
              </Label>
              <Input
                innerRef={ref => this.addressInputRef = ref}
                // invalid={validation.adresse.isInvalid}
                name="adresse"
                value={adresse}
                placeholder={t('common.address')}
                onChange={this.handleAddressChange}/>
              <FormFeedback>
                {validation.adresse.message}
              </FormFeedback>
            </FormGroup>
            <div className="log-divider">
              <span className="bg-light">
                {t('modals.ze_associated')}
              </span>
            </div>
            <FormGroup>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className="cursor-pointer"
                    onClick={this.setMode.bind(null, 'general')}
                    active={mode === 'general'}>
                    {t('common.general')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="cursor-pointer"
                    onClick={this.setMode.bind(null, 'prestataire')}
                    active={mode === 'prestataire'}>
                    {t('common.prestataire')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="cursor-pointer"
                    onClick={this.setMode.bind(null, 'detail')}
                    active={mode === 'detail'}>
                    {t('common.detail')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="cursor-pointer"
                    onClick={this.setMode.bind(null, 'characteristics')}
                    active={mode === 'characteristics'}>
                    {t('common.characteristics')}
                  </NavLink>
                </NavItem>
              </Nav>
            </FormGroup>

            {mode === "general" &&
            <React.Fragment>
              <FormGroup>
                <Label>
                  {t('common.label')}
                </Label>
                <Input
                  name="zLabel"
                  invalid={validation.zLabel.isInvalid}
                  placeholder={t('common.label')}
                  onChange={this.onChange}
                  value={zLabel}/>
                <FormFeedback>
                  {validation.zLabel.message}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>
                  {t('common.taxonomies')}
                </Label>
                <ToolkitProvider
                  search
                  keyField={'tId'}
                  columns={this.columns}
                  bootstrap4={true}
                  data={taxonomies}>
                  {props => (
                    <div>
                      <DebounceInput
                        onChange={(e) => props.searchProps.onSearch(e.target.value)}/>
                      <BootstrapTable
                        bordered={false}
                        rowStyle={{cursor: 'pointer'}}
                        noDataIndication={() => t('common.no_data')}
                        pagination={paginationFactory({
                          sizePerPage: 5
                        })}
                        {...props.baseProps}/>
                    </div>
                  )}
                </ToolkitProvider>
              </FormGroup>
            </React.Fragment>}

            {mode === "prestataire" &&
            <React.Fragment>
              <FormGroup>
                <Label>
                  {t('common.prestataires')}
                </Label>
                <Select2Wrapper
                  name="selectedPrestataires"
                  data={prestatairesChoices}
                  value={selectedPrestataires}
                  options={{
                    placeholder: t('common.prestataires')
                  }}
                  onChange={this.onPrestatairesChange}/>
              </FormGroup>
              {!!selectedPrestataires &&
              <>
                <FormGroup>
                  <Label>
                    {t('common.responsable')}
                  </Label>
                  <Select2Wrapper
                    multiple
                    value={selectedResponsables}
                    data={[
                      ...responsableChoices.map(responsable => {
                        return (
                          {text: responsable.text, id: responsable.id})
                      })
                    ]}
                    options={{placeholder: t('common.responsable')}}
                    onChange={this.onChangeResponsables}
                    name="selectedResponsables"/>
                </FormGroup>
                {selectedResponsables.length > 1 &&
                <FormGroup>
                  <Label>
                    {t('common.responsable')} principal
                  </Label>
                  <Input
                    name="mainResponsableId"
                    value={mainResponsableId}
                    placeholder={t('common.responsable')}
                    type="select"
                    onChange={this.onChange}>
                    {responsableChoices.filter(r => selectedResponsables.includes(r.id)).map(responsable => {
                      return (
                        <option value={responsable.id} key={responsable.id}>
                          {responsable.text}
                        </option>)
                    })}
                  </Input>
                </FormGroup>}
              </>}
              <FormGroup>
                <Label>
                  {t('common.brand')}
                </Label>
                <Input
                  name="brand_id"
                  value={brand_id}
                  placeholder={t('common.brand')}
                  type="select"
                  onChange={this.onChange}>
                  <option value="">{t('modals.select_brand')}</option>
                  {brands.map(brand => {
                    return (
                      <option value={brand.id} key={brand.id}>
                        {brand.label}
                      </option>
                    )})}
                </Input>
              </FormGroup>
            </React.Fragment>}

            {mode === "detail" &&
            <React.Fragment>
              <FormGroup>
                <Label>
                  {t('common.comment')}
                </Label>
                <textarea
                  className="form-control"
                  value={comment}
                  name="comment"
                  onChange={this.onChange}
                  rows="3"
                  placeholder={t('common.comment')}/>
              </FormGroup>
              <div className="log-divider">
                <span className="bg-light"><i className="fa fa-fw fa-clock-o"/> {t('modals.opening_time')}</span>
              </div>
              <FormGroup>
                <WeekTimetable
                  onChange={this.handleSchedulesChange}
                  value={schedules}/>
              </FormGroup>
            </React.Fragment>}

            {mode === 'characteristics' &&
            <React.Fragment>
              {!zone_characteristics.length &&
              <FormGroup>
                {t('modals.Aucune caractéristique définie')}
              </FormGroup>}
              {!!zone_characteristics.length && zone_characteristics.map((ch,idx) => {
                return (
                  <FormGroup key={idx}>
                    <Label>
                      {ch.name}
                    </Label>
                    <Input
                      placeholder={ch.name}
                      type='text'
                      onChange={e => this.handleCharacteristicContentChange(idx, e.target.value)}
                      value={ch.label}/>
                  </FormGroup>
                )
              })}
            </React.Fragment>}

          </ModalBody>
          <ModalFooter>
            <Button color="secondary" type='button' onClick={this.reject}>{t('common.close')}</Button>
            <Button color="success">{t('common.valid')}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {SetupNewSiteObject};
export default SetupNewSiteObject;
