import React from "react";
import {connect} from "react-redux";
import {Row, Col, Label, Nav, NavItem, Button} from "reactstrap";
import {NavLink} from "react-router-dom";
import {Redirect, Route} from "react-router";
import {SyntheticView} from "./SyntheticView";
import {ThemesView} from "../Reporting/ThemesView";
import {TotalI2CHeader} from "./TotalI2CHeader";
import {DetailedRapport} from "./DetailedRapport/DetailedRapport";
import {filters} from "./utils";
import {fetchClientAnalyses} from "../../../actions/orm/Analyse";
import {addForePaasProcessing, fetchForePaasProcessing} from "../../../actions/orm/RapportsProcessing";
import {createClientSelectorById} from "../../selectors/clients";
import {createAnalyseByIdSelector} from "../../selectors/analyse";
import _ from "lodash";
import {SyntheticProgressPlanView} from "../Reporting/SyntheticProgressPlanView/SyntheticProgressPlanView";
import {fetchClientOrganisation,fetchClientThemesTree,fetchClientAudits} from "../../../actions/orm/Client";
import {startPending, stopPending} from "../../../actions/ui";
import createSelector from "../../../tools/createSelector";
import qs from "query-string";
import waitPromiseBeforeMount from "../../../HOC/waitPromiseBeforeMount";
import {FiltersContextProvider, Filter, withFilters} from "@jeremyglanum/filters";
import {fetchPrestataires} from "../../../actions/orm/Prestataire";
import {fetchAllParameters} from "../../../actions/orm/Parameter";
import {Adm_The_Gen, hasPermission} from "../../../constants/modules";

const clientSelector = createClientSelectorById();
const analyseSelector = createAnalyseByIdSelector();
const prestataireByZoneIdSelector = createSelector(
  (orm, {zoneId}) => {
    const zone = orm.ZoneExploitation.withId(zoneId);
    if (!zone)
      return null;

    const prestataires = zone.prestataires.toRefArray();

    return !!prestataires.length ? prestataires[0] : null;
  }
);
const getProcessing = createSelector((orm) => orm.RapportsProcessing.all().toRefArray());

@withFilters(({zones_exploitation: {value}}) => ({
  zoneId: !!value.length ? value[0].value : null
}))
@connect((state, {zoneId}) => ({
  prestataire: prestataireByZoneIdSelector({zoneId})
}))
class PrestataireDisplay extends React.PureComponent {
  render(){
    const {prestataire} = this.props;
    return !!prestataire ? prestataire.label : t('common.not_defined')
  }
}
@waitPromiseBeforeMount(async ({session: {currentClientId}}, {}, dispatch) => {
  dispatch(startPending());

  await dispatch(fetchClientAnalyses({client_id: currentClientId}));

  dispatch(stopPending());
})
@withFilters(({analyses: {value}, zones_exploitation: {value: zeValue}, vagues: {value: vaValue}}) => ({
  zoneId: !!zeValue.length ? zeValue[0].value : null,
  vague: !!vaValue.length ? vaValue[0].value : null,
  analyse: analyseSelector({id: !!value[0] ? value[0].value : null})
}))
@connect(({session: {currentClientId}}) => ({
  clientId: currentClientId,
  client: clientSelector({id: currentClientId})
}))
class Reporting extends React.PureComponent {
  state = {
    loadPrint: false,
    init: false,
    loadingResponse: true,
    enableForePaasProcessing: false
  };

  themeViewFilters = [
    "clients",
    "analyses",
    "vagues",
    "zones_exploitation"
  ];

  async componentDidMount(){
    this.props.dispatch(startPending());

    await Promise.all([
      this.props.dispatch(fetchClientOrganisation({client_id: this.props.clientId})),
      this.props.dispatch(fetchClientAudits({client_id: this.props.clientId})),
      this.props.dispatch(fetchClientAnalyses({client_id: this.props.clientId})),
      this.props.dispatch(fetchClientThemesTree({client_id: this.props.clientId})),
      this.props.dispatch(fetchAllParameters()),
      this.props.dispatch(fetchPrestataires())
    ]);

    this.props.dispatch(stopPending());
    this.setState({init: true});

    setInterval(() => {
      this.setForePaasProcessing();
    }, 60000);
  };

  forceForePaasProcessing = () => {
    const {analyse, zoneId, vague, dispatch} = this.props;

    dispatch(addForePaasProcessing({
      analyse_id: analyse.id,
      vague: vague,
      zone_exploitation_id: zoneId
    }));

    this.setState({enableForePaasProcessing:true});
  };

  async setForePaasProcessing() {
    const {analyse, zoneId, vague} = this.props;

    if ( !(!!analyse && !!vague && !!zoneId) )
      return

    const getProcessing = await this.props.dispatch(fetchForePaasProcessing())

    this.setState({enableForePaasProcessing: (getProcessing.filter(v => v.analyse_id == analyse.id && v.vague == vague && v.zone_exploitation_id == zoneId)).length>0});
  }

  componentDidUpdate(){
      this.setForePaasProcessing();
  }

  loadingResponse = (value) => {
    this.setState({loadingResponse: value});
  };

  loadPrint = () => {
    // this.setState({loadPrint: !this.state.loadPrint});
    this.setState({loadPrint: true});
  };

  render(){
    const {init, loadingResponse, enableForePaasProcessing} = this.state;
    const {match: {params: {section}}, location, client, analyse} = this.props;
    const zoneLabel = client.label_zone_exploitation;

    const passageLow = !!analyse ? analyse.passage_low : 70;
    const passageHigh = !!analyse ? analyse.passage_high : 90;

    if (!init)
      return null;
    if (!section)
      return <Redirect to={`/reporting/mono/synthetic${location.search}`}/>;

    return (
      <div className="page-inner">
        <div className="page-section">
          {!analyse &&
          <div>
            <h6>Vous n'avez accès à aucun rapport.</h6>
          </div>}
          <Row>
            <Col sm={2} className={!analyse ? "d-none" : ""}>
              <div>
                <Label><strong>{t('common.analyse')} :</strong></Label>
                <Filter placeholder={`${t('common.Sélectionner')}...`}
                        id="analyses"/>
                <br/>
                <Label><strong>{t('common.vague')} :</strong></Label>
                <Filter placeholder={`${t('common.Sélectionner')}...`}
                        id="vagues"/>
                <br/>
                <Label><strong>{zoneLabel} :</strong></Label>
                <Filter placeholder={`${t('common.Sélectionner')}...`}
                        id="zones_exploitation"/>
                {hasPermission(Adm_The_Gen) &&
                <Button size="sm"
                  color="success"
                  class="mt-3 btn-block"
                  disabled={enableForePaasProcessing}
                  onClick={this.forceForePaasProcessing}>
                    {!enableForePaasProcessing ? <i class="fas fa-cog"></i> : <i class="fas fa-cog fa-spin"></i>}
                    &nbsp;
                    {!enableForePaasProcessing ? t('common.btnForePaas') : t('common.btnForePaasInProgress')}
                </Button>}
              </div>
            </Col>
            {!!analyse &&
            <Col sm={10}>
              <div className="nav-scroller border-bottom mb-3 flex-shrink-0">
                <Nav tabs className="navigation">
                  <NavItem>
                    <NavLink
                      exact
                      className={'nav-link'}
                      to={`/reporting/mono/synthetic`}>
                      {t('reporting.synthetic_rapport')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={'nav-link'}
                      to={`/reporting/mono/themes`}>
                      {t('reporting.thematic_rapport')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={'nav-link'}
                      to={`/reporting/mono/detailed`}>
                      {t('reporting.detailed_rapport')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={'nav-link'}
                      to={`/reporting/mono/syntheticpp`}>
                      {t('common.progress_plans')}
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <div class="mb-1">
                <strong>{t("common.prestataire")} :</strong> <PrestataireDisplay/>
              </div>
              <div className="mb-4">
                <TotalI2CHeader
                  section={section}
                  passageLow={passageLow}
                  passageHigh={passageHigh}
                  loadPrint={this.loadPrint}
                  loadingResponse={loadingResponse}
                  zoneLabel={client.label_zone_exploitation}/>
              </div>

              <div className="containerscroll">
              <Route path={'/reporting/mono/synthetic'}
                     render={(props) => <SyntheticView {...props} passageLow={passageLow} passageHigh={passageHigh}/>}/>
              <Route path={'/reporting/mono/themes'}
                     render={(props) => <ThemesView {...props} passageLow={passageLow} passageHigh={passageHigh} dynamicFilters={this.themeViewFilters}/>}/>
              <Route path={'/reporting/mono/detailed'}
                     render={(props) => <DetailedRapport {...props} passageLow={passageLow} passageHigh={passageHigh} loadPrint={this.state.loadPrint} loadingResponse={this.loadingResponse}/>}/>
              <Route path={'/reporting/mono/syntheticpp'}
                     render={(props) => <SyntheticProgressPlanView {...props} client={client} passageLow={passageLow} passageHigh={passageHigh}/>}/>
              </div>
            </Col>}
          </Row>
        </div>
      </div>
    )
  }
}

@connect(({session: {currentClientId}}) => ({
  clientId: currentClientId
}))
class Index extends React.PureComponent {
  constructor(props) {
    super(props);

    this.filters = _.cloneDeep(filters);

    const {location} = this.props;
    const queryParams = qs.parse(location.search, {arrayFormat: 'bracket'});

    this.filters.clients.value[0].value = this.props.clientId;

    Object.keys(queryParams).forEach(key => {
      const value = queryParams[key];
      if (!!this.filters[key]){
          this.filters[key].value = ((Array.isArray(value)) ? value.map(i => ({value: parseInt(i)})) : [value].map(i => ({value: parseInt(i)})));
      }
    });

  }

  render(){
    return (
      <FiltersContextProvider filters={this.filters}>
        <Reporting {...this.props}/>
      </FiltersContextProvider>
    )
  }
}

export {Index};
export default Index;
