import React from "react";
import {connect} from "react-redux";
import {setTaxonomyItemSelection} from "../../actions/modals";
import {
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CustomInput
} from "reactstrap";
import {createTaxonomyTreeSelector} from "../selectors/taxonomy";
import {Tree, decorators} from "@jeremyglanum/neqo-tree";
import {DebounceInput} from "@jeremyglanum/ui";

const taxonomyTreeSelector = createTaxonomyTreeSelector();

const localDecorators = {
  ...decorators,
  Container: ({node, name, terminal, listeners, onClick, decorators}) => {

    return (
      <div class="ocmTree__container">
        {!terminal && !!node.children && !!node.children.length &&
        <decorators.Toggle node={node} onClick={onClick}/>}
        <span class="ocmTree__header">
          {!!terminal ?
            <CustomInput
              type="checkbox"
              id={`${name}${node.id}`}
              className="d-inline-block"
              checked={!!node.checked}
              onChange={listeners.handleCheck}
              label={node.label} /> :
            <span>{node.label}</span>}
          </span>
      </div>
    )
  }
};

@connect(({modals: {taxonomyItemSelection: {taxonomyId, defaultSelectedId, resolve, reject}}}) => ({
  taxonomyId,
  defaultSelectedId,
  resolve,
  reject
}))
class TaxonomyItemSelection extends React.PureComponent {
  constructor(props){
    super(props);
    const {taxonomyId, defaultSelectedId} = this.props;

    this.state = {
      closing: false,
      search: '',
      taxonomyTree: [],
      selectedItem: null
    };

    const taxonomy = taxonomyTreeSelector({taxId: taxonomyId}, true);
    this.state.taxonomyTree = taxonomy;

    if (!!defaultSelectedId){
      let flat = flatTree(taxonomy);
      let item = flat.find(i => i.id === defaultSelectedId);

      item.checked = true;
      this.state.selectedItem = item;
    }
  }

  onToggle = (node, toggled) => {
    node.toggled = !node.toggled;
    this.forceUpdate();
  };

  handleChange = (e) => {
    this.setState({[e.target.name] : e.target.value});
  };

  handleCheck = (node) => {
    if (!!this.state.selectedItem)
      this.state.selectedItem.checked = false;
    node.checked = true;
    this.setState({selectedItem: node});
  };

  resolve = () => {
    const {selectedItem : {id, parent_id, client_id, tax_id, label, freeze, created, modified}} = this.state;

    this.props.resolve({
      id,
      parent_id,
      client_id,
      tax_id,
      label,
      freeze,
      created,
      modified
    });
    this.closing();
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setTaxonomyItemSelection({
      open: false
    }));
  };

  render(){
    const {closing, search, selectedItem, taxonomyTree} = this.state;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {t('modals.choose_taxonomy_element')} "{taxonomyTree[0].label}"
        </ModalHeader>
        <ModalBody>
          <div>
            <DebounceInput className="mb-2" value={search} onChange={this.handleChange}/>
            <Tree
              search={true}
              searchValue={search}
              searchFields={['label']}
              data={taxonomyTree}
              listeners={{
                handleCheck: this.handleCheck
              }}
              name={"SelectTaxItemTree"}
              decorators={localDecorators}
              onToggle={this.onToggle}/>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={this.reject}>
            {t('common.cancel')}
          </Button>
          <Button
            disabled={!selectedItem}
            onClick={this.resolve}
            color="success">
            {t('common.confirm')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export {TaxonomyItemSelection};
export default TaxonomyItemSelection;
