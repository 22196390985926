import './i18n';
import 'jquery/dist/jquery.min';
import 'popper.js/dist/popper.min';
import 'bootstrap/dist/js/bootstrap.min';
import 'react-select2-wrapper/';
import React from 'react';
import 'react-tippy/dist/tippy.css';
import 'fullcalendar';
import 'fullcalendar/dist/locale/fr';
import 'fullcalendar/dist/fullcalendar.min.css';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import "nouislider/dist/nouislider.css";
import 'react-select2-wrapper/css/select2.min.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/regular.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import '@fortawesome/fontawesome-free/css/brands.min.css';
import '../css/theme/scss/theme.scss';
import '../css/styles.scss';
import 'open-iconic/font/css/open-iconic-bootstrap.scss';
import 'flatpickr/dist/themes/airbnb.css';
import 'react-input-range/lib/css/index.css';
import {render} from 'react-dom';
import store from './store';
import { Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import moment from 'moment';
import {Base} from "./Base";
import {config} from "@jeremyglanum/forepaas-utils";
import {defaults} from "react-chartjs-2";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {initBootstrapDatatableDropdownFix, addUtilsToWindow} from "@jeremyglanum/utils";

//disable popper gpu acceleration to fit with looper theme
popper.default.Defaults.modifiers.computeStyle.gpuAcceleration = false;

_.merge(defaults, {
  global: {
    plugins: {
      labels: false
    }
  }
});
moment.locale('fr');

config.forepass_api_url = !!_ENV.FOREPAAS_API_URL ? `${_ENV.FOREPAAS_API_URL}/main-api` : `https://ocm.forepaas.io/main-api`;
config.app_id = !!_ENV.FOREPAAS_APP_ID ? _ENV.FOREPAAS_APP_ID : "62014a4ebb13331c00000182";

Object.defineProperty(config, "jwt", {
  get: () => {
    return store.getState().session.forepaasJWT;
  }
});

initBootstrapDatatableDropdownFix();
addUtilsToWindow();
// $(document).on('click', '.react-bs-table-sizePerPage-dropdown .dropdown-menu a', function (e) {
//   e.preventDefault();
// });

export const Translation2 = (id) => {
  const { t, i18n } = useTranslation();
  window.t = t;
  window.i18n = i18n;

  return null;
};

window.getForepaasUrl = (endpoint = '/qb/query') => {
  const jwt = store.getState().session.forepaasJWT;

  return `${config.forepass_api_url}${endpoint}?app_id=${config.app_id}&jwt=${jwt}`;
};

render(
  <Provider store={store}>
    <Router>
      <>
        <Translation2 />
        <Base/>
      </>
    </Router>
  </Provider>,
  document.getElementById('root')
);
