import React from "react";
import {SingleValueI2CDoughnut} from "./SingleValueI2CDoughnut";
import {Col, Row, CardBody, Card} from "reactstrap";
import {ThemesTree} from "./ThemesView/ThemesTree";
import {EvolText} from "./TextRenderers";
import {Print} from "../MonoReporting/Print";
import {connect} from "react-redux";
import {createClientSelectorById} from "../../selectors/clients";
import {questionnaireTreeI2cFetch} from "../../tools/Filters/updateFunctions";
import {withFilters, FRequest} from "@jeremyglanum/filters";
import {addNumericalValueInTree} from "../../selectors/questionnaire";

const clientSelector = createClientSelectorById();

@withFilters(({analyses: {value}, zones_exploitation: {value: zeValue}}) => ({
  analyseLabel: !!value && value[0] ? value[0].label : null,
  zeLabel: !!zeValue && zeValue[0] ? zeValue[0].label : null,
}))
@connect(({session: {currentClientId}}) => ({
  client: clientSelector({id: currentClientId})
}))
class ThemesView extends React.PureComponent {
  render(){
    const {passageLow, passageHigh, dynamicFilters, zeLabel, analyseLabel, client} = this.props;

    return (
      <div>
        <FRequest
          dynamicParameters={dynamicFilters}
          updateFunc={questionnaireTreeI2cFetch}>
          {(result, loading, error, filters) => {
            if (loading && !result)
              return <div>{t('reporting.loading')}</div>;

            if (!result || !result.length)
              return <div class="text-center">{t('reporting.no_data')}</div>;

            const data = !!client.numerical ? addNumericalValueInTree(result) : result;
            const analyseId = filters.analyses[0];
            const vague = filters.vagues[0];
            const zoneId = !!filters.zones_exploitation ? filters.zones_exploitation[0] : null;
            const hideEvol = vague === 1;

            return (
              <div>
                <Print
                  view={'theme'}
                  node={data}
                  analyseId={analyseId}
                  vague={vague}
                  zoneId={zoneId}
                  zeLabel={zeLabel}
                  clientLabel={client.label}
                  analyseLabel={analyseLabel}
                  passageLow={passageLow}
                  passageHigh={passageHigh}/>
                {data.map(theme => {
                  return (
                    <Card key={theme.id}>
                      <CardBody>
                        <Row>
                          <Col sm={2} class="d-flex flex-column justify-content-center">
                            <h6 class="text-center">{theme.label}</h6>
                            <div class="d-flex">
                              <div class="flex-grow-1" style={{width: '33%'}}/>
                              <div class="flex-grow-1 d-flex align-items-center justify-content-center" style={{width: '33%'}}>
                                <SingleValueI2CDoughnut
                                  passageLow={passageLow}
                                  passageHigh={passageHigh}
                                  value={theme.value}
                                  size={60}
                                  fontSize={15}/>
                              </div>
                              <div className="flex-grow-1 d-flex align-items-center justify-content-center"
                                   style={{width: '33%'}}>
                                <h5 className="m-0">
                                  {!hideEvol &&
                                  <EvolText value={theme.diff}/>}
                                </h5>
                              </div>
                            </div>
                          </Col>
                          <Col sm={10}>
                            <ThemesTree
                              passageLow={passageLow}
                              passageHigh={passageHigh}
                              hideEvol={hideEvol}
                              client={client}
                              data={theme.children}/>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )
                })}
              </div>
            )
          }}
        </FRequest>
      </div>
    )
  }
}

export {ThemesView};
export default ThemesView;
