import React from "react";
import {createSelector} from "../../../../tools/createSelector";
import {Col, Label, Row, Card, Collapse, CardHeader, CardBody, Button, Input, Badge} from "reactstrap";
import {Select} from "@jeremyglanum/ui";
import {connect} from "react-redux";
import {zonesExploitationSelector,
  themeQuestionsSelector,
  rootThemesSelector,
  subThemesSelector,
  prestataireSelector} from "../../../tools/Filters/filterItemsSelectors";
import {createClientRootTaxonomiesSelector} from "../../../selectors/taxonomy";
import {withFilters, FiltersTracker} from "@jeremyglanum/filters";
import {AnalyseViewTree} from "./AnalyseViewTree";
import {AnalyseViewEvolutionTable} from "./AnalyseViewEvolutionTable";
import {createClientSelectorById} from "../../../selectors/clients";

const rootTaxonomiesSelector = createClientRootTaxonomiesSelector();
const clientSelector = createClientSelectorById();
const taxonomyOptionsSelector = createSelector(
  async (orm, {id}) => {
    const taxonomies = orm.Taxonomy.all().filter(t => t.parent_id === id).toRefArray();

    return taxonomies.map(t => ({
      label: t.label,
      value: t.id
    }));
  }
);

export const rootThemeLabelSelector = createSelector(
  (orm, {items, model}) => {
    items.forEach(i => {
      const id = !!i.id ? i.id : i.value;
      const item = orm[model].withId(id);
      if (!item){
        i.numericValue = '';
        i.numericValueForSort = '';
        i.rootThemeLabel = '';
        i.model = model;
        return;
      }

      let theme = !!item.theme_id ? item.theme_id : !!item.parent_id ? item.parent_id : item;
      let numericValue = `${theme.ranking + 1}.${item.ranking + 1}`;

      while (!!theme.parent_id) {
        numericValue = `${theme.parent_id.ranking + 1}.${numericValue}`;
        theme = theme.parent_id;
      }

      i.numericValue = numericValue;
      i.numericValueForSort = numericValue.replace(/\d+/g, n => +n+100000);
      i.rootThemeLabel = theme.label;
      i.model = model;
    });
  }
);

export const setThemeRanking = createSelector(
  (orm, {items}) => {
    items.forEach(i => {
      const item = orm.Theme.withId(i.id);
      i.ranking = item.ranking;
    });
  }
);

const selectors = {
  zoneExploitation: zonesExploitationSelector,
  prestataire: prestataireSelector,
  theme: rootThemesSelector,
  question: themeQuestionsSelector,
  subTheme: subThemesSelector,
  taxonomy: taxonomyOptionsSelector
};

@connect(({session: {currentClientId}}) => ({
  clientId: currentClientId,
  client: clientSelector({id: currentClientId})
}))
class AnalyseViewBase extends React.PureComponent {
  constructor(props) {
    super(props);
    this.groupOptions = [
      {
        label: `${Labels.ze}`,
        labelModel: 'ZoneExploitation',
        value: "zoneExploitation",
        selectorType: 'zoneExploitation',
        type: 'zoneExploitation',
        sortField: 'label',
        fappId: 'zone_exploitation_id',
        fappFilter: {
          level: [1]
        }
      },
      {
        label: t('common.theme'),
        labelModel: 'Theme',
        value: 'theme',
        selectorType: 'theme',
        type: 'questionnaire',
        sortField: 'ranking',
        fappId: 'cible_id',
        fappFilter: {
          level: [1]
        }
      },
      {
        label: t('common.subTheme'),
        labelModel: 'Theme',
        value: 'subTheme',
        selectorType: 'subTheme',
        type: 'questionnaire',
        sortField: 'label',
        fappId: 'cible_id',
        fappFilter: {
          level: {not_in: [-1, 0, 1]}
        }
      },
      {
        label: t('common.question'),
        labelModel: 'ThemeQuestion',
        value: 'question',
        selectorType: 'question',
        type: 'questionnaire',
        sortField: 'numericValueForSort',
        fappId: 'cible_id',
        fappFilter: {
          level: [0]
        }
      },
      {
        label: t('common.prestataire'),
        labelModel: 'Prestataire',
        value: 'prestataire',
        selectorType: 'prestataire',
        type: 'prestataire',
        sortField: 'label',
        fappId: 'prestataire_id',
        fappFilter: {
          level: [1]
        }
      }
    ];

    const rootTaxonomies = rootTaxonomiesSelector({clientId: this.props.clientId}, true);
    this.groupOptions = this.groupOptions.concat(rootTaxonomies.map((t, idx) => ({
      label: t.label,
      labelModel: 'Taxonomy',
      value: `taxonomy${idx}`,
      type: 'taxonomy',
      sortField: 'label',
      selectorType: 'taxonomy',
      id: t.id,
      fappId: `tax_${idx + 1}_level_2`,
      fappFilter: {
        level: [1]
      }
    })));

    const groupItem = this.groupOptions[1];
    const axisOptions = this.getAxisOptions(groupItem.type);

    this.displayTypeOptions = [
      {label: t('reporting.Évolution'), value: 'evolution'},
      {label: t('reporting.Vague en cours'), value: 'vague'}
    ];

    this.state = {
      groupOptions: this.groupOptions,
      groupValue: groupItem,
      axisOptions: axisOptions,
      axisValue: axisOptions[0],
      axisItems: [],
      axisItemsFilterValue: [],
      groupItems: [],
      groupItemsFilterValues: [],
      displayType: this.displayTypeOptions[0]
    };
  }

  async componentDidMount(){
    const {clientId} = this.props;
    const {axisValue} = this.state;

    const axisItems = await selectors[axisValue.value]({
      clients: [clientId],
      id: axisValue.id
    });

    this.setState({axisItems: axisItems});
  }

  getAxisOptions = (itemType) => {
    const options = this.groupOptions.filter(i => i.type !== itemType);

    return options;
  };

  axisItemChange = async (item) => {
    const {clientId} = this.props;
    const newAxisItems = await selectors[item.selectorType]({
      clients: [clientId],
      id: item.id
    });

    this.setState({
      axisValue: item,
      axisItems: newAxisItems,
      axisItemsFilterValue: []
    });
  };

  groupItemChange = async (item) => {
    const {axisValue, axisItems, axisItemsFilterValue} = this.state;
    const {clientId} = this.props;

    const axisOptions = this.getAxisOptions(item.type);
    let newAxisValue = axisValue;
    if (!axisOptions.find(i => i.value === newAxisValue.value))
      newAxisValue = axisOptions[0];

    const newAxisItems = newAxisValue !== axisValue ?
      await selectors[newAxisValue.value]({
        clients: [clientId],
        id: newAxisValue.id
      }) :
      axisItems;

    this.setState({
      groupValue: item,
      axisOptions: axisOptions,
      axisValue: newAxisValue,
      axisItems: newAxisItems,
      axisItemsFilterValue: newAxisValue !== axisValue ? [] : axisItemsFilterValue
    });
  };

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  render(){
    const {
      groupValue,
      groupOptions,
      axisOptions,
      axisValue,
      displayType,
      axisItemsFilterValue,
      axisItems
    } = this.state;
    const {
      passageLow,
      passageHigh,
      analyseId,
      vague,
      client,
      filters
    } = this.props;
    return (
      <div>
        <Row class="mb-2">
          <Col sm={3} class="d-flex flex-column align-items-center">
            <Label>
              {t('reporting.Sélectionner un axe de comparaison')} :
            </Label>
            <div style={{maxWidth:'100%', width: 200}}>
              <Select
                onChange={this.groupItemChange}
                value={groupValue}
                options={groupOptions}/>
            </div>
          </Col>
          <Col sm={3} class="d-flex flex-column align-items-center">
            <Label>
              {t('reporting.Sélectionner un axe d’analyse')} :
            </Label>
            <div style={{maxWidth:'100%', width: 200}}>
              <Select
                onChange={this.axisItemChange}
                value={axisValue}
                options={axisOptions}/>
            </div>
          </Col>
          <Col sm={3} class="d-flex flex-column align-items-center">
            <Label>
              {t('reporting.Filtrer un axe d’analyse')} :
            </Label>
            <div style={{maxWidth:'100%', width: 200}}>
              <Select
                isMulti
                onChange={(value) => this.handleChange({
                  target:{
                    value: value,
                    name: 'axisItemsFilterValue'
                  }
                })}
                value={axisItemsFilterValue}
                options={axisItems}/>
            </div>
          </Col>
          <Col sm={3} class="d-flex flex-column align-items-center">
            <Label>
              {t('pp.view_mode')} :
            </Label>
            <div style={{maxWidth:'100%', width: 200}}>
              <Select
                value={displayType}
                options={this.displayTypeOptions}
                onChange={(value) => this.handleChange({
                  target:{
                    value: value,
                    name: 'displayType'
                  }
                })}/>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {displayType.value === 'evolution' &&
            <AnalyseViewEvolutionTable
              passageLow={passageLow}
              passageHigh={passageHigh}
              analyseId={analyseId}
              vague={vague}
              client={client}
              groupValue={groupValue}
              axisItemsFilterValue={axisItemsFilterValue}
              axisValue={axisValue}
              filters={filters}
            />}
            {displayType.value === 'vague' &&
            <AnalyseViewTree
              passageLow={passageLow}
              passageHigh={passageHigh}
              analyseId={analyseId}
              vague={vague}
              axisItemsFilterValue={axisItemsFilterValue}
              axisValue={axisValue}
              groupValue={groupValue}/>}
          </Col>
        </Row>
      </div>
    )
  }
}

const AnalyseView = ({passageLow, passageHigh}) => {
  return (
    <FiltersTracker
      filters={[
        'analyses',
        'vagues'
      ]}>
      {(filters) => {
        if (!filters)
          return null;
        const {analyses, vagues} = filters;

        if (!analyses.length || !vagues.length)
          return null;

        return (
          <AnalyseViewBase
            passageLow={passageLow}
            passageHigh={passageHigh}
            analyseId={analyses[0]}
            vague={vagues[0]}/>
        )
      }}
    </FiltersTracker>
  )
};

export {AnalyseView};
export default AnalyseView;
