import React from "react";
import {FileInput} from "@jeremyglanum/ui";
import {api} from "../../tools/api";
import {setViewImage} from "../../actions/modals";
import {store} from "../../store";

class WritableImageCarousel extends React.PureComponent {
  uploadImage = (e) => {
    const file = e.target.files[0];
    const extension = file.name.split(".");
    let defaultName = "";
    extension.map((n, i) => {
      if (i !== extension.length - 1)
        defaultName += i === 0 ? n : `.${n}`;
    });
    const reader = new FileReader();
    const {onChange, value, name} = this.props;

    reader.onload = async (e) => {
      const response = await api.file.upload({data: `extension:${extension[extension.length - 1]};${e.target.result}`});

      onChange({
        target: {
          name,
          value: [response.path, ...value]
        }
      });
    };
    reader.readAsDataURL(file);
  };

  viewImage = (src) => {
    store.dispatch(setViewImage({
      open:true,
      url: src
    }));
  };

  removeImage = (idx) => {
    const {onChange, value, name} = this.props;

    const newValue = value.filter((v, index) => index !== idx);

    onChange({
      target: {
        name,
        value: newValue
      }
    })
  };

  render(){
    const {size, value} = this.props;

    return (
      <div class="ocm-image-carousel">
        <FileInput
          inputProps={{
            onChange:this.uploadImage,
            name: 'path',
            accept: "image/*"
          }}
          style={{
            width: size,
            paddingBottom: size
          }}
          title={t('common.add_image')}
          class="image adder d-flex align-items-center justify-content-center">
          <i className="fa fa-plus-circle"/>
        </FileInput>
        {value.map((src, idx) => {
          return (
            <div class="image"
                 key={idx}
                 onClick={() => this.viewImage(src)}
                 style={{
                   backgroundImage: `url(${src})`,
                   width: size,
                   paddingBottom: size
                 }}>
              <i class="fa fa-times remove-button"
                 title={t('common.remove_image')}
                 onClick={(e) => {
                   e.stopPropagation();
                    this.removeImage(idx);
                 }}/>
            </div>
          )
        })}
      </div>
    )
  }
}

export {WritableImageCarousel};
export default WritableImageCarousel;
