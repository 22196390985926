import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ListGroup,
  ListGroupItem,
  Badge,
  FormFeedback, Alert, Nav, NavItem, NavLink
} from "reactstrap";
import {Editor} from 'react-draft-wysiwyg';
import {EditorState} from 'draft-js';
import {convertToHTML} from "draft-convert";
import {setProgressPlanModelCreate} from "../../actions/modals";
import {Form, Input, FormGroup, Label, InputGroup, InputGroupAddon} from "reactstrap";
import {connect} from "react-redux";
import {LoadingButton} from "../common/LoadingButton";
import {addProgressModel, fetchProgressModels} from "../../actions/orm";
import fv from "../../tools/formValidator/FormValidator";
import createSelector from "../../tools/createSelector";
import {OrganisationIconPlaceholder} from "../common/ImagePlaceholders";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {DebounceInput} from "@jeremyglanum/ui";
import BootstrapTable from "react-bootstrap-table-next";
import {defaultPaginationFactory} from "../../constants/ui";
import {createThemeTreeByClientIdSelector} from "../selectors/theme";
import ThemesHierarchyTree from "../tools/ThemesHierarchyTree";
import {tFunction} from "../tools/Translation";
import {createClientSelectorById} from "../selectors/clients";

const clientSelector = createClientSelectorById();
const localFv = new fv([
  {
    field: 'label',
    method: 'isEmpty',
    validWhen: false,
    message: tFunction('modals.label_specified')
  },
  {
    field: 'themes',
    method: "areTreeItemsSelected",
    validWhen: true,
    message: tFunction('modals.choose_theme_or_question')
  },
  {
    field: 'themes',
    method: 'areTreeLevelsEquals',
    validWhen: true,
    message: tFunction('modals.choose_same_level_theme')
  }
]);

const rootClientsSelector = createSelector(
  (orm) => {
    return orm.Client.all().filter(i => !i.parent_id && !!i.scope).toRefArray()
  }
);

const progressModelsSelectorByClientIds = createSelector(
  (orm, {clientIds}) => {

    return clientIds.map(clientId => {
        const client = orm.Client.withId(clientId);
        const progressModels = client.progressModels.toModelArray();

        return progressModels.map(model => {
          return {
            ...model.ref,
            client: model.client_id.ref,
            actions: model.modelActions.toRefArray(),
            themes: model.themes.toRefArray(),
            themes_questions: model.themes_questions.toModelArray().map(tq => ({
              ...tq.ref,
              question: tq.ref.question_id
            }))
          }
        });
      }).flat()
  }
);

const themesTreeSelector = createThemeTreeByClientIdSelector();

@connect(({modals: {progressPlanModelCreate : {clientId}}}) => ({
  clientId,
  client: clientSelector({id: clientId})
}))
export default class ProgressPlanModelCreate extends React.PureComponent {
  state = {
    level: 1,
    label: '',
    actions: [],
    action: '',
    loading: false,
    themes: [],
    errors: localFv.valid(),
    closing: false,
    mode: 'new',
    models: [],
    editorState: EditorState.createEmpty()
  };
  tableRef = null;

  inputLabel = React.createRef();

  columns = [
    {
      dataField: 'label',
      text: t('common.label'),
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return (
          <span>
            <Badge color={"info"}>
               {!!row.themes.length ? t('common.theme_badge') : t('common.question_badge')}
            </Badge>
            &nbsp;
            {row.label}
          </span>
        )
      }
    },
    {
      dataField: 'client',
      text: t('common.client'),
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return (
          <span>
            <OrganisationIconPlaceholder src={row.client.path} size={20}/>
            &nbsp;&nbsp;
            {row.client.label}
          </span>
        )
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === 'asc')
          return rowA.client.label.localeCompare(rowB.client.label);
        return rowB.client.label.localeCompare(rowA.client.label);
      }
    },
    {
      dataField: 'level',
      text: t('common.priority'),
      sort: true
    },
    {
      dataField: 'themes',
      text: t('common.themes_questions'),
      formatter: (cell, row, rowIndex) => {
        return (
          <span>
            {!!row.themes.length ?
              row.themes.map(i => i.label).join(', ') :
              row.themes_questions.map(i => !!i.question.title ? i.question.title : i.question.label).join(', ')
            }
          </span>
        )
      }
    }
  ];

  async componentDidMount(){
    const rootClients = rootClientsSelector({});
    const themes = themesTreeSelector({
      clientId: this.props.clientId,
      sortByRank: true,
      setupLevels: true,
      setNumericValue: !!this.props.client.numerical
    }, true);

    await this.props.dispatch(fetchProgressModels({
      clients: rootClients.map(i => i.id)
    }));

    const models = progressModelsSelectorByClientIds({clientIds: rootClients.map(i => i.id)});
    this.inputLabel.focus();
    this.setState({models, themes: themes});
  };

  setMode = (mode) => {
    this.setState({mode});
  };

  onChange = ({target: {name, value}}) => {
    this.setState({[name]: value});
  };

  handleEditorStateChange = (editorState) => {
    const action = convertToHTML(editorState.getCurrentContent());
    this.setState({
      editorState,
      action: action
    });
  };

  processRequest = ({themes, themes_questions}) => {
    const {clientId, add} = this.props;
    const {level, label, actions} = this.state;

    this.setState({loading: true});
    this.props.dispatch(addProgressModel({
      client_id: clientId,
      label,
      level,
      actions,
      themes_questions,
      themes
    })).then(resp => {
      this.setState({loading: false});
      this.closing();
    });
  };

  copyModels = async () => {
    const {clientId} = this.props;
    const selectedModels = this.tableRef.selectionContext.state.selected
      .map(id => this.state.models.find(i => i.id === id));

    const calls = selectedModels.map(model => {
      return this.props.dispatch(addProgressModel({
        client_id: clientId,
        label: model.label,
        level: model.level,
        actions: model.actions.map(a => a.label),
        themes_questions: model.themes_questions.map(t => t.id),
        themes: model.themes.map(t => t.id)
      }));
    });

    this.setState({loading: true});
    await Promise.all(calls);
    this.setState({loading: false});
    this.closing();
  };

  onSubmit = (e) => {
    e.preventDefault();
    const {themes, mode} = this.state;

    if (mode === 'copy'){
      this.copyModels();
      return;
    }
    const horizontal = flatTree(themes);

    const validation = localFv.validate({
      label: this.state.label,
      themes: {
        tree: themes,
        horizontal: horizontal
      }
    });

    if (!validation.isValid){
      this.setState({errors: validation});
      return;
    }

    const checked = horizontal.filter(i => !!i.checked);
    let type = !!checked.find(i => i.nodeModel === 'theme') ? 'theme' : 'themeQuestion';

    if (type === 'theme'){
      let minLevel = 999;
      const themes = checked.filter(c => c.nodeModel === 'theme');
      themes.forEach(t => {
        if (t.level < minLevel)
          minLevel = t.level;
      });
      const filteredThemes = themes.filter(t => t.level === minLevel);
      this.processRequest({
        themes: filteredThemes.map(t => t.id)
      });
    } else {
      this.processRequest({
        themes_questions: checked.map(c => c.id)
      });
    }
  };

  addAction = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {action} = this.state;

    if (!!action.length) {
      this.setState({
        actions: [...this.state.actions, this.state.action],
        editorState: ''})
    }
  };

  removeAction = (index) => {
    this.setState({actions: this.state.actions.filter((a,i) => i !== index)});
  };

  close = () => {
    this.props.dispatch(setProgressPlanModelCreate({
      open: false
    }));
  };

  closing = () => {
    this.setState({closing: true});
  };

  renderProfileTable = () => {
    const {models} = this.state;

    return (
      <FormGroup>
        <ToolkitProvider
          search
          keyField={'id'}
          columns={this.columns}
          bootstrap4={true}
          data={models}>
          {props => (
            <div>
              <DebounceInput className="mb-2" onChange={(e) => props.searchProps.onSearch(e.target.value)}/>
              <BootstrapTable
                ref={ref => this.tableRef = ref}
                bordered={false}
                noDataIndication={() => t('common.no_data')}
                rowStyle={{cursor: 'pointer'}}
                selectRow={{
                  mode: 'checkbox',
                  clickToSelect: true,
                  bgColor: "#e6e6ed",
                  selectionRenderer: ({ mode, checked, disabled }) => (
                    <div className="cursor-pointer custom-control custom-control-nolabel custom-checkbox">
                      <input readOnly type="checkbox" className="custom-control-input" checked={checked}/>
                      <label className="cursor-pointer custom-control-label"/>
                    </div>
                  ),
                  selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
                    <div className="cursor-pointer custom-control custom-control-nolabel custom-checkbox">
                      <input readOnly type="checkbox" className="custom-control-input" checked={checked}/>
                      <label className="cursor-pointer custom-control-label"/>
                    </div>
                  )
                }}
                pagination={defaultPaginationFactory()}
                {...props.baseProps}/>
            </div>
          )}
        </ToolkitProvider>
      </FormGroup>
    )
  };

  render(){
    const {mode, closing, themes,label, actions, level, action, loading, errors} = this.state;

    return (
      <Modal isOpen={!closing}
             onClosed={this.close}
             size={mode === 'copy' ? 'lg' : null}>
        <ModalHeader toggle={this.closing}>{t('pp.add_model')}</ModalHeader>
        <Form onSubmit={this.onSubmit}>
          <ModalBody>
            <FormGroup>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    onClick={this.setMode.bind(null, 'new')}
                    active={mode === 'new'}>
                    {t('common.new')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={this.setMode.bind(null, 'copy')}
                    active={mode === 'copy'}>
                    {t('modals.copy_models')}
                  </NavLink>
                </NavItem>
              </Nav>
            </FormGroup>
            {mode === 'new' &&
            <React.Fragment>
              <FormGroup>
                <ThemesHierarchyTree
                  name={"modelCreateQtTree"}
                  data={themes}/>
              </FormGroup>
              <FormGroup>
                <Label>{t('common.label')}</Label>
                <Input type="text"
                       invalid={errors.label.isInvalid}
                       innerRef={(input) => (this.inputLabel = input)}
                       value={label}
                       onChange={this.onChange}
                       placeholder={t('common.label')}
                       name="label"/>
                {errors.label.isInvalid &&
                <FormFeedback>
                  {errors.label.message}
                </FormFeedback>}
              </FormGroup>
              <FormGroup>
                <Label>{t('common.priority')}</Label>
                <Input type="select"
                       placeholder={t('common.priority')}
                       name="level"
                       value={level}
                       onChange={this.onChange}>
                  {[1,2,3].map((o, i) => {
                    return <option key={i}>{o}</option>
                  })}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>{t('modals.add_action_preconisation')}</Label>
                <div>
                <Editor
                      placeholder={t('common.action_preconisation')}
                      editorState={this.state.editorState}
                      onEditorStateChange={this.handleEditorStateChange}
                      toolbar={{
                        options: ['inline', 'list', 'blockType'],
                        inline: {
                          inDropdown: false,
                          options: ['bold', 'italic', 'underline', 'strikethrough']
                        },
                        blockType: {
                          inDropdown: true,
                          options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
                        },
                        list: {
                          inDropdown: false,
                          options: ['unordered', 'ordered']
                        }
                      }}/>
                    <Button color="primary" type="button" onClick={this.addAction}>{t('common.add')}</Button>
                </div>
              </FormGroup>
              <FormGroup>
                <ListGroup className="list-group-bordered">
                  <div class="list-group-header">
                    {t('common.action_preconisation')}
                  </div>
                  {actions.map((a,i) => (
                    <ListGroupItem key={i}>
                      <div className="list-group-item-body" dangerouslySetInnerHTML={{__html: a}} />
                      <div class="list-group-item-figure">
                        <button className="btn btn-sm btn-light" type="button" onClick={() => this.removeAction(i)}>
                          <i className="fa fa-times"/>
                        </button>
                      </div>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </FormGroup>
              {errors.themes.isInvalid &&
              <Alert color="danger">
                {errors.themes.message}
              </Alert>}
            </React.Fragment>}
            {mode === 'copy' &&
            this.renderProfileTable()}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary"
                    type="button"
                    disabled={loading}
                    onClick={this.closing}>
              {t('common.close')}
            </Button>
            <LoadingButton color="success"
                           loading={loading}
                           disabled={loading}
                           type="submit">
              {t('common.valid')}
            </LoadingButton>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}
