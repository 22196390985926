import React from "react";
import {Tree, decorators} from "@jeremyglanum/neqo-tree";
import {Badge, CustomInput, Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {DebounceInput} from "@jeremyglanum/ui";
import {utils} from "@jeremyglanum/neqo-tree";
import {CustomControlCheckbox} from "@jeremyglanum/ui";
import {QuestionBadge, SubThemeBadge, ThemeBadge} from "../common/TreeItemsBadges";

const localDecorators = {
  ...decorators,
  Container: ({node, name, terminal, listeners, onClick, decorators}) => {
    return (
      <div class="ocmTree__container" style={{paddingLeft: terminal ? 19 : ''}}>
        {!terminal && !!node.children && !!node.children.length &&
        <decorators.Toggle node={node} onClick={onClick}/>}
        <span class="ocmTree__header">
          {!terminal &&
          <React.Fragment>
            <i className="oi oi-list-rich cursor-pointer" onClick={listeners.handleRecursiveCheck}/>
            &nbsp;
          </React.Fragment>}
          <CustomControlCheckbox
            className={'custom-control-inline m-0'}
            readOnly={!!node.nodeLocked}
            disabled={!!node.nodeLocked}
            indeterminate={node.indeterminate}
            onChange={listeners.handleCheck}
            checked={!!node.checked}>
            {node.nodeModel === 'themeQuestion' ?
              <QuestionBadge/>:
              !!node.parent_id ?
                <SubThemeBadge/>:
                <ThemeBadge/>}
            &nbsp;
            {node.nodeModel === 'themeQuestion' ?
              <><b>{!!node.numericValue ? `${node.numericValue} ` : ''}</b>{node.question.title}</> : node.label}
          </CustomControlCheckbox>
          {/*<label className="custom-control custom-control-inline custom-checkbox m-0">*/}
          {/*<input type="checkbox"*/}
          {/*readOnly={!!node.nodeLocked}*/}
          {/*disabled={!!node.nodeLocked}*/}
          {/*className="custom-control-input"*/}
          {/*onChange={listeners.handleCheck}*/}
          {/*checked={!!node.checked}/>*/}
          {/*<div className="custom-control-label">*/}
          {/*<Badge color="primary" title={t('common.theme')} class="badge-subtle">*/}
          {/*{node.nodeModel === 'themeQuestion' ? t('common.question_badge') : t('common.theme_badge')}*/}
          {/*</Badge>*/}
          {/*&nbsp;*/}
          {/*{node.nodeModel === 'themeQuestion' ? node.question.title : node.label}*/}
          {/*</div>*/}
          {/*</label>*/}
       </span>
      </div>
    )
  }
};

class ThemesHierarchyTree extends React.PureComponent {
  state = {
    search: ''
  };

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  onToggle = (node, toggled) => {
    node.toggled = !node.toggled;
    this.forceUpdate();
  };

  handleCheck = (node, e) => {
    utils.indeterminateCheckFunction(node);
    this.forceUpdate();
    if (!!this.props.onChange)
      this.props.onChange(this.props.data);
  };

  handleRecursiveCheck = (node, e) => {
    utils.recursiveIndeterminateCheckFunction(node);
    this.forceUpdate();
    if (!!this.props.onChange)
      this.props.onChange(this.props.data);
  };

  nodeMatchFunction = (node, searchValue, searchFields) => {
    const searchLowerCase = searchValue.toLowerCase();
    if (!node.question && node.label.toLowerCase().includes(searchLowerCase))
      return true;
    else if (!!node.question && node.question.title.toLowerCase().includes(searchLowerCase))
      return true;
    return false;
  };

  render(){
    const {search} = this.state;

    return (
      <div>
        <DebounceInput className="mb-2" value={search} onChange={this.handleChange}/>
        <Tree
          search={true}
          searchValue={search}
          nodeMatchFunction={this.nodeMatchFunction}
          showMatchedPath={true}
          listeners={{
            handleCheck: this.handleCheck,
            handleRecursiveCheck: this.handleRecursiveCheck
          }}
          decorators={localDecorators}
          {...this.props}/>
      </div>
    )
  }
}

export {ThemesHierarchyTree};
export default ThemesHierarchyTree;
