import React from "react";
import {Tree, decorators} from "@jeremyglanum/neqo-tree";
import {CustomInput, Badge, Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {DebounceSearchInput} from "../common/DebounceSearchInput";

const localDecorators = {
  ...decorators,
  Container: ({node, name, terminal, listeners, onClick, decorators}) => {
    return (
      <div class="ocmTree__container">
        {!terminal && !!node.children && !!node.children.length &&
        <decorators.Toggle node={node} onClick={onClick}/>}
        <span class="ocmTree__header">
            <Badge color="primary" className="badge-subtle">
              {node.type[0].toUpperCase()}
            </Badge>
          &nbsp;
          <CustomInput
            type="checkbox"
            id={`${name}${node.type}${node.id}`}
            className="d-inline-block"
            checked={!!node.checked}
            onChange={listeners.handleCheck}
            label={node.label} />
          </span>
      </div>
    )
  }
};

class GenericOrganisationCheckableTree extends React.PureComponent {
  state = {
    search: ''
  };

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  onToggle = (node, toggled) => {
    node.toggled = !node.toggled;
    this.forceUpdate();
  };

  handleCheck = (node, e) => {
    function recursiveCheck(node, state) {
      node.checked = state;
      if (!!node.children && !!node.children.length){
        node.children.forEach(item => {
          recursiveCheck(item, state);
        });
      }
    }

    if (!node.checked)
      recursiveCheck(node, true);
    else
      recursiveCheck(node, false);
    this.forceUpdate();
    if (!!this.props.onChange)
      this.props.onChange(this.props.data);
  };

  organisationsSearchFunc = (data, searchValue, searchFields) => {
    function update(tree){
      tree.forEach(node => {
        node.hiddenBySearch = true;
        node.matched = false;
        for (let i = 0; i < searchFields.length; i++){
          if (!!node[searchFields[i]] && node[searchFields[i]].toLowerCase().includes(searchValue.toLowerCase())){
            node.hiddenBySearch = false;
            node.matched = true;
            node.toggled = true;
            break;
          }
        }
        if (!!node.children && !!node.children.length){
          if (update(node.children)){
            node.hiddenBySearch = false;
            node.toggled = true;
          }
        }
      });
      for (let i = 0; i < tree.length; i++){
        if (!tree[i].hiddenBySearch)
          return true;
      }
      return false;
    }
    update(data);

    function showMatchedPath(nodes, matched = false){
      nodes.forEach(node => {
        node.hiddenBySearch = !node.matched;
        if (!!matched){
          node.matched = matched;
          node.hiddenBySearch = !matched;
          node.toggled = true;
        }
        if (!!node.children && !!node.children.length){
          showMatchedPath(node.children, !!node.matched);
          if (!!(node.children.find(n => !!n.matched))){
            node.matched = true;
            node.hiddenBySearch = false;
            node.toggled = true;
          }
        }
      });
    }
    showMatchedPath(data);

    if (!!this.props.onChange)
      this.props.onChange(data);
  };

  render(){
    const {search} = this.state;

    return (
      <div>
        <DebounceSearchInput
          className="mb-2"
          value={search}
          onChange={this.handleChange}/>
        <Tree
          onToggle={this.onToggle}
          searchFunction={this.organisationsSearchFunc}
          searchValue={search}
          search={true}
          searchFields={['label']}
          listeners={{
            handleCheck: this.handleCheck
          }}
          decorators={localDecorators}
          {...this.props}/>
      </div>
    )
  }
}

export {GenericOrganisationCheckableTree};
export default GenericOrganisationCheckableTree;
