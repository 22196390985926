import React from "react";
import {ocmGreen} from "../../../constants/ui";
import {CheckableTree} from "@jeremyglanum/neqo-tree";
import {UncontrolledDropdown, DropdownToggle, DropdownMenu, CustomInput} from "reactstrap";

class TaxonomyFilters extends React.PureComponent {
  isCheck = (t) => {
    const filtered = t.filter(item => {
      if (!!item.checked)
        return true;
      else if (!!item.children.length)
        return this.isCheck(item.children);
    });

    return !!filtered.length
  };

  render(){
    const {options, value, onCheck, handleTreeChange} = this.props;

    return (
      <div>
        {options.map((t, idx) => {
          return (
            <UncontrolledDropdown direction="down" key={t.id}>
              <DropdownToggle caret class="w-100 mb-2">
                {(!!t.checked || !!this.isCheck(t.children)) &&
                <i className="mr-2 fa fa-check validation-check" style={{color: ocmGreen}}/>}
                {t.label}
              </DropdownToggle>
              <DropdownMenu class="p-2" style={{maxHeight: 250, overflow: 'auto'}}>
                <CheckableTree
                  searchDisabled={true}
                  onChange={handleTreeChange}
                  data={t}/>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        })}
      </div>
    )
  }
}

export {TaxonomyFilters};
export default TaxonomyFilters;
