import React from "react";
import {connect} from "react-redux";
import {Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {closeModal} from "../../actions/modals";
import {createRootClientsSelector} from "../selectors/clients";
import {checkableThemesTreeDecorators} from "../tools/treebeardDecorators";
import Select from 'react-select';
import {startPending, stopPending} from "../../actions/ui";
import {fetchClientThemesTree} from "../../actions/orm/Client";
import {createThemeTreeByClientIdSelector} from "../selectors/theme";
import {CheckableTree, utils} from "@jeremyglanum/neqo-tree";
import _ from "lodash";

const clientsSelector = createRootClientsSelector();
const themeTreeSelector = createThemeTreeByClientIdSelector();

@connect(({ocmModals: {chooseClientThemeTreeItems: {resolve, reject}}}) => ({
  resolve,
  reject
}))
class ChooseClientThemeTreeItems extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      closing: false,
      clients: [],
      selectedClient: {},
      tree: []
    }
  }

  handleSelectChange = async (item) => {
    const {selectedClient} = this.state;

    this.setState({selectedClient: item});

    if (selectedClient.value !== item.value){
      const id = item.value;

      this.props.dispatch(startPending());
      await this.props.dispatch(fetchClientThemesTree({
        client_id: id
      }));
      this.props.dispatch(stopPending());
      const tree = themeTreeSelector({
        clientId: id,
        sortByRank: true
      }, true);

      this.setState({tree: tree});
    }
  };

  async componentDidMount(){
    let tree = [];
    const clients = clientsSelector();

    const clientOptions = _.sortBy(clients.map(c => ({label: c.label, value: c.id})), (c) => _.lowerCase(c.label));
    const selectedClient = clientOptions[0];

    if (!!selectedClient){
      const id = selectedClient.value;

      this.props.dispatch(startPending());
      await this.props.dispatch(fetchClientThemesTree({
        client_id: id
      }));
      this.props.dispatch(stopPending());
      tree = themeTreeSelector({
        clientId: id,
        sortByRank: true
      }, true);
    }
    this.setState({
      clients: clientOptions,
      selectedClient: selectedClient,
      tree
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {tree} = this.state;

    const horizontalTree = flatTree(tree);
    const checkedThemeIds = horizontalTree.filter(n => n.nodeModel === 'theme' && (!!n.checked || !!n.indeterminate)).map(i => i.id);
    const checkedThemeQuestionIds = horizontalTree.filter(n => n.nodeModel === 'themeQuestion' && (!!n.checked || !!n.indeterminate)).map(i => i.id);

    this.props.resolve({
      themes_id: checkedThemeIds,
      themes_questions_id: checkedThemeQuestionIds
    });
    this.closing();
  };

  close = () => {
    this.props.dispatch(closeModal('chooseClientThemeTreeItems'));
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  handleRecursiveCheck = (node) => {
    utils.recursiveCheckFunction(node);
    this.forceUpdate();
  };

  handleCheck = (node) => {
    utils.indeterminateCheckFunction(node);
    this.forceUpdate();
  };

  render(){
    const {closing, clients, selectedClient, tree} = this.state;
    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {t('modals.choose_elements')}
        </ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label>
                {t('common.client')}
              </Label>
              <Select
                noOptionsMessage={() => t('modals.no_clients_available')}
                onChange={this.handleSelectChange}
                value={selectedClient}
                options={clients}/>
            </FormGroup>
            <FormGroup>
              <CheckableTree
                listeners={{
                  handleCheck: this.handleCheck,
                  handleRecursiveCheck: this.handleRecursiveCheck
                }}
                decorators={checkableThemesTreeDecorators}
                name="themes_tree"
                data={tree}/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              onClick={this.reject}>
              {t('common.close')}
            </Button>
            <Button
              color="success">
              {t('common.valid')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {ChooseClientThemeTreeItems};
export default ChooseClientThemeTreeItems;
