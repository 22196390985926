import React from "react";
import {Tree, decorators, utils} from "@jeremyglanum/neqo-tree";
import {Badge, CustomInput, Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {DebounceInput} from "@jeremyglanum/ui";
import {CustomControlCheckbox} from "../common/CustomInputs";

const localDecorators = {
  ...decorators,
  Container: ({node, name, terminal, listeners, onClick, decorators}) => {
    return (
      <div class="ocmTree__container">
        {!terminal && !!node.children && !!node.children.length &&
        <decorators.Toggle node={node} onClick={onClick}/>}
        <span class="ocmTree__header">
                  <CustomControlCheckbox
                    className="d-inline-block m-0"
                    readOnly={!!node.readOnly}
                    disabled={!!node.readOnly}
                    checked={!!node.checked}
                    indeterminate={node.indeterminate}
                    onChange={listeners.handleCheck}>
                        <Badge color="primary" title={t('common.theme')} class="badge-subtle">
                          {node.nodeModel === 'themeQuestion' ? t('common.question_badge') : t('common.theme_badge')}
                        </Badge>
                    &nbsp;
                    {node.nodeModel === 'themeQuestion' ? node.question.title : node.label}
                  </CustomControlCheckbox>
       </span>
      </div>
    )
  }
};

const checkParentNodes = (tree) => {
  tree.forEach(node => {
    if (!!node.children && !!node.children.length){
      checkParentNodes(node.children);
      for (let i = 0; i < node.children.length; i++) {
        if (!node.children[i].checked)
          return;
      }

      node.checked = true;
    }
  });
};

class ChooseQuestionnaireItemsTree extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      search: ''
    };

    utils.checkableTreeSetup(this.props.data);
  }

  componentDidUpdate(prevProps){
    if (this.props.data !== prevProps.data){
      utils.checkableTreeSetup(this.props.data);
      this.forceUpdate();
    }
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  onToggle = (node, toggled) => {
    node.toggled = !node.toggled;
    this.forceUpdate();
  };

  handleCheck = (node, e) => {
    node.checked = !node.checked;
    this.forceUpdate();
    if (!!this.props.onChange)
      this.props.onChange(this.props.data);
  };

  handleRecursiveCheck = (node, e) => {
    utils.recursiveCheckFunction(node);

    this.forceUpdate();
    if (!!this.props.onChange)
      this.props.onChange(this.props.data);
  };

  nodeMatchFunction = (node, searchValue, searchFields) => {
    const searchLowerCase = searchValue.toLowerCase();
    if (!node.question && node.label.toLowerCase().includes(searchLowerCase))
      return true;
    else if (!!node.question && node.question.title.toLowerCase().includes(searchLowerCase))
      return true;
    return false;
  };

  render(){
    const {search} = this.state;

    return (
      <div>
        <DebounceInput className="mb-2" onChange={this.handleChange} value={search}/>
        <Tree
          search={true}
          searchValue={search}
          nodeMatchFunction={this.nodeMatchFunction}
          showMatchedPath={true}
          listeners={{
            handleCheck: this.handleRecursiveCheck
          }}
          decorators={localDecorators}
          {...this.props}/>
      </div>
    )
  }
}

export {ChooseQuestionnaireItemsTree};
export default ChooseQuestionnaireItemsTree;
