import React from "react";
import {
  Badge,
  Button,
  CustomInput,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import {setChooseQuestion} from "../../actions/modals";
import {connect} from "react-redux";
import {decorators, Tree} from "@jeremyglanum/neqo-tree";
import {DebounceSearchInput} from "../common/DebounceSearchInput";
import {tFunction} from "../tools/Translation";

const ThemeHeader = ({node}) => {
  return (
    <span className="ocmTree__header">
      <Badge color="primary" title={t('common.theme')} class="badge-subtle">
        {tFunction('common.theme_badge')}
      </Badge>
      &nbsp;
      <span>{node.label}</span>
    </span>
  )
};

const QuestionHeader = ({node, listeners: {handleCheck}}) => {
  return (
    <span className="ocmTree__header">
      <Badge color="primary" title={t('common.question')} class="badge-subtle">
        {tFunction('common.question_badge')}
      </Badge>
      &nbsp;
      <CustomInput
        type="checkbox"
        readOnly={node.disabled}
        disabled={node.disabled}
        id={`${name}${node.nodeModel}${node.id}`}
        className="d-inline-block"
        checked={!!node.checked}
        onChange={handleCheck}
        label={node.question.title}/>
    </span>
  )
};

const localDecorators = {
  ...decorators,
  Container: ({node, name, terminal, listeners, onClick, decorators}) => {
    return (
      <div className="ocmTree__container">
        {!terminal && !!node.children && !!node.children.length &&
        <decorators.Toggle node={node} onClick={onClick}/>}
        {node.nodeModel === 'theme' ?
          <ThemeHeader node={node} listeners={listeners}/> :
          <QuestionHeader node={node} listeners={listeners}/>}
      </div>
    )
  }
};

@connect(({modals: {chooseQuestion: {tree, resolve, reject}}}) => ({
  tree,
  resolve,
  reject
}))
class ChooseQuestion extends React.PureComponent {
  constructor(props) {
    super(props);
    const {tree} = this.props;

    this.state = {
      closing: false,
      tree: tree,
      cursor: null,
      search: ''
    }
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {cursor} = this.state;
    const {resolve} = this.props;

    if (!cursor)
      return;

    resolve(cursor);
    this.closing();
  };

  closing = () => {
    this.setState({closing: true});
  };

  close = () => {
    this.props.dispatch(setChooseQuestion({
      open: false
    }));
  };

  reject = () => {
    this.props.reject();
    this.closing();
  };

  handleCheck = (node) => {
    const {cursor} = this.state;

    if (!!cursor)
      cursor.checked = false;
    node.checked = true;
    this.state.cursor = node;
    this.forceUpdate();
  };

  listeners = {
    handleCheck: this.handleCheck
  };

  nodeMatchFunction = (node, searchValue, searchFields) => {
    const searchLowerCase = searchValue.toLowerCase();
    if (!node.question && node.label.toLowerCase().includes(searchLowerCase))
      return true;
    else if (!!node.question && node.question.title.toLowerCase().includes(searchLowerCase))
      return true;
    return false;
  };

  render(){
    const {closing, cursor, search} = this.state;
    const {tree} = this.props;

    return (
      <Modal isOpen={!closing} onClosed={this.close}>
        <ModalHeader toggle={this.reject}>
          {t('modals.choose_question')}
        </ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <FormGroup>
              <DebounceSearchInput
                value={search}
                onChange={this.handleChange}/>
            </FormGroup>
            <FormGroup>
              <Label>{t('modals.choose_question')}</Label>
              <Tree
                search={true}
                searchValue={search}
                nodeMatchFunction={this.nodeMatchFunction}
                showMatchedPath={true}
                decorators={localDecorators}
                data={tree}
                listeners={this.listeners}/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type='button'
              onClick={this.reject}>
              {t('common.cancel')}
            </Button>
            <Button
              disabled={!cursor}
              color="success">
              {t('common.choose')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export {ChooseQuestion};
export default ChooseQuestion;
