import React from "react";
import {connect} from "react-redux";
import {Row, Col, CardBody, Card} from "reactstrap";
import {decorators} from "@jeremyglanum/neqo-tree";
import {fetchClientAudits, fetchClientThemesTree} from "../../../../actions/orm/Client";
import {fetchAuditQuestionnaire, fetchClientQuestionnaire} from "../../../../actions/orm/Questionnaire";
import {fetchClientAnalyses} from "../../../../actions/orm/Analyse";
import {createReportByClientIdSelector} from "../../../selectors/report";
import {startPending, stopPending} from "../../../../actions/ui";
import waitPromiseBeforeMount from "../../../../HOC/waitPromiseBeforeMount";
import {QuestionsTree} from "./QuestionsTree";
import {Print} from "../Print";
import {createClientSelectorById} from "../../../selectors/clients";
import {getClientAnalysesSelector} from "../../../../ormSelectors/analyse";
import {createQuestionsByClientIdSelector} from "../../../selectors/question";
import {createQuestionnaireByClientIdSelector} from "../../../selectors/questionnaire";
import {createAuditsFullByClientIdSelector} from "../../../selectors/audit";
import {questionnaireTreeI2cFetch} from "../../../tools/Filters/updateFunctions";
import {FRequest, withFilters} from "@jeremyglanum/filters";
import { Button } from "@jeremyglanum/ui";
import axios from "axios";

const clientSelector = createClientSelectorById();
const getAudits = createAuditsFullByClientIdSelector();
const getReports = createReportByClientIdSelector();
const getQuestionsByClientId = createQuestionsByClientIdSelector();
const getQuestionnaires = createQuestionnaireByClientIdSelector();

@waitPromiseBeforeMount(async ({session: {currentClientId}}, {}, dispatch) => {
  dispatch(startPending());
  await Promise.all([
    dispatch(fetchClientThemesTree({client_id: currentClientId})),
    dispatch(fetchClientAudits({client_id: currentClientId})),
    dispatch(fetchClientQuestionnaire({client_id: currentClientId})),
    dispatch(fetchClientAnalyses({client_id: currentClientId})),
  ]);
  dispatch(stopPending());
})
@withFilters(({analyses: {value}, zones_exploitation: {value: zeValue}}) => ({
  analyseLabel: !!value && value[0] ? value[0].label : null,
  zeLabel: !!zeValue && zeValue[0] ? zeValue[0].label : null
}))
@connect(({session: {currentClientId}}) => ({
  client: clientSelector({id: currentClientId}),
  audits: getAudits({clientId: currentClientId}),
  reports: getReports({clientId: currentClientId}),
  questions: getQuestionsByClientId({clientId: currentClientId}),
  questionnaires: getQuestionnaires({client_id: currentClientId, setNumericValue: true}),
  analyses: getClientAnalysesSelector({client_id: currentClientId}),
}))

class DetailedRapport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state =
    {
      fetchResponses: {},
      fetchAllComments:[],
      allUnfold:false,
      PrevStateZe: {},
      PrevStateVa: {},
      PrevStateAn: {},
      hideEvol:false
    };
  }

  fetchResponses = async (ze_id) => {
    if (!this.state.fetchResponses[ze_id]) {
      this.props.loadingResponse(true);

      await Promise.all(this.props.reports.map(r => {
        this.props.dispatch(fetchAuditQuestionnaire({report_id: r.id, zone_exploitations_id: ze_id}));
      }));
      this.state.fetchResponses[ze_id] = true;
      this.setState({fetchResponses: this.state.fetchResponses});

      setTimeout(() => this.props.loadingResponse(false), 1000)
    }
  };
  fetchAllComments = (analyse_id, vague_id, zone_exploitations_id) => {
    this.setState({PrevStateZe: zone_exploitations_id})
    this.setState({PrevStateVa: vague_id})
    this.setState({PrevStateAn: analyse_id})
    let datas = axios.get(`/api/rapports/${analyse_id}/vague/${vague_id}/zone-exploitations/${zone_exploitations_id}`)
    .then(response => response.data)
    return datas
  }

  updateFunc = questionnaireTreeI2cFetch;
  render() {
    const {analyseLabel, zeLabel, client, passageLow, passageHigh } = this.props;

    return (
      <div>
        <FRequest
          dynamicParameters={[
            "clients",
            "analyses",
            "vagues",
            "zones_exploitation"
          ]}
          updateFunc={this.updateFunc}>
          {(result, loading, error, filters) => {
            if (loading && !result)
              return <div>{t('reporting.loading')}</div>;

            if (!result || !result.length)
                return <div class="text-center">{t('reporting.no_data')}</div>;

            if (!this.state.fetchResponses[filters.zones_exploitation[0]]) {
              this.fetchResponses(filters.zones_exploitation[0]);
              return <div>{t('reporting.loading')}</div>;
            }

            if (this.state.PrevStateZe != filters.zones_exploitation[0] ||
               this.state.PrevStateVa != filters.vagues[0] ||
               this.state.PrevStateAn != filters.analyses[0]){
              this.fetchAllComments(filters.analyses[0], filters.vagues[0], filters.zones_exploitation[0])
              .then(comments => this.setState({fetchAllComments: comments.map(commentGenerals => commentGenerals)}))
            }
            const analyseId = filters.analyses[0];
            const filterVague = filters.vagues[0];
            const zoneId = filters.zones_exploitation[0];

            function getChildren (children, props) {
              return children.map(item => {
                if (item.type === "theme" && (!item.children.length || item.children[0].type !== "question"))
                  return {
                    ...item,
                    children: getChildren(item.children, props)
                  };
                else {
                  return {
                    ...item,
                    children: item.children.map(q => {
                      const {audits, analyses} = props;
                      const questions = props.questions.filter( q => q.from_questionnaire_freezed === false);
                      const analyse = analyses.find(a => a.id === analyseId);
                      const vague = !!analyse ? analyse.vagues.find(v => v.vague === filterVague) : null;
                      const audit_ids = !!analyse ? analyse.sources.filter(s => s.model === 'Audit').map(s => Number(s.id)) : [];
                      const reports_id = !!analyse ? vague.sources.filter(s => s.model === 'Report').map(s => s.id) : [];
                      const zeId = zoneId;
                      let questionnaires = [];
                      let data = {};
                      props.questionnaires.filter(z => !!audit_ids.includes(z.audit_id)).map(q2 => {
                        questionnaires.push(q2);
                      });
                      const questionnaire = questionnaires.find(q3 => q3.themes_question.theme_id === q.theme_id && q3.themes_question.question_id === q.question.id);
                      data = {
                        ...questions.find(q2 => q.question.id === q2.id),
                        value: q.value,
                        diff: q.diff,
                        numericValue: !!client.numerical && questionnaire ? questionnaire.numericValue : '',
                        lastVagueValue: q.lastVagueValue,
                        children: []
                      };

                      questionnaires.filter(q2 =>
                        !!q2.themes_question && q2.themes_question.theme_id === q.theme_id && q2.themes_question.question_id === q.id
                      ).map(q2 => {
                        if (!!q2.response) {
                          q2.response.map(r => {
                            if (((!!r.comment && r.comment !== '') ||
                              (!!r.files && !!r.files.length) || (!!r.photos && !!r.photos.length)) || ((!!q.objectif && !!q.objectif !== null) && (!!q.type && !!q.type.includes('free'))) &&
                              !!reports_id.includes(r.report_id) && r.zone_exploitation_id === zeId)
                              data.children.push({...r, label: audits.find(a => a.id === q2.audit_id).label});
                          });
                        }
                      });
                      return data;
                    })
                  }
                }

              });
            }
            const datas = getChildren(result, this.props);
            function childrenComponent (theme, fetchResponses, analyseId, filterVague, zoneId, themeId, allUnfold) {
              if (theme.type === "theme") {
                return <section key={theme.id}>
                  <div className='card-body'>
                        <h4 className='card-title'>{theme.label}</h4>
                    {theme.children.map(item => {
                      if (item.type === "theme")
                        return childrenComponent(item, fetchResponses, analyseId, filterVague, zoneId, theme.id, allUnfold);
                      else if (!!fetchResponses)
                        return <QuestionsTree key={item.id} analyseId={analyseId} passageLow={passageLow} passageHigh={passageHigh}
                                              vague={filterVague} zoneId={zoneId} row={item} themeId={theme.id} toggled={allUnfold}/>;
                    })}
                  </div>
                </section>
              }
              return <QuestionsTree key={theme.id} analyseId={analyseId} passageLow={passageLow} passageHigh={passageHigh}
                                    vague={filterVague} zoneId={zoneId} row={theme} themeId={themeId} toggled={allUnfold}/>;
            }
            return (
              <>
                <Row>
                  <Col xs={12} class="d-flex justify-content-end mb-2">
                    <Button size="sm" color="primary" onClick={()=>this.setState({allUnfold:!this.state.allUnfold})}>
                      {!this.state.allUnfold ? t('common.unfold_all_comment') : t('common.bend_all_comment')}
                    </Button>
                  </Col>
                </Row>
                <Print
                  view={'detailed'}
                  node={datas}
                  result={result}
                  analyseId={analyseId}
                  vague={filterVague}
                  zoneId={zoneId}
                  zeLabel={zeLabel}
                  clientLabel={client.label}
                  analyseLabel={analyseLabel}
                  passageLow={passageLow}
                  passageHigh={passageHigh}/>
                {this.state.fetchAllComments.length != 0  &&
                  <Card class="mb-4 p-1" key={"1"}>
                    <CardBody>
                      <Row>
                        <Col sm={2} class="d-flex flex-column justify-content-center">
                          <h4 className='text-center'>{"Commentaire général"}</h4>
                        </Col>
                          <Col sm={10} style={{borderLeft: '2px dashed #B8B8B8'}}>
                          {this.state.fetchAllComments.map((commentGeneral, index) => (
                            commentGeneral?.commentaire_general &&
                            <section key={index} className="ocmTree__container pl-2" style={{borderBottom: "2px dashed rgb(190, 190, 190)"}}>
                              <Row>
                              <Col sm={12}>
                              <span className={`ocmTree__header d-flex flex-grow-1 w-100 ${'flex-column'}`}>
                                <Col sm={12}>
                                <decorators.Toggle node={{toggled: this.state.hideEvol}} onClick={()=> this.setState({hideEvol: !this.state.hideEvol}) }/>
                                <span className={'ml-2'}>{commentGeneral?.commentaire_general}</span>
                                </Col>
                                {this.state.hideEvol &&
                                <div className={`ml-5 mt-2 mb-1`}>
                                  <>
                                    <span className="flex-grow-1" style={{wordWrap: 'break-word'}}>
                                      <b><i>{`Questionnaire : ${commentGeneral?.questionnaire}, Vague : ${commentGeneral?.vague_nom} `}</i></b>
                                    </span>
                                    <br/>
                                  </>
                                </div>
                                }
                              </span>
                              </Col>
                              </Row>
                            </section>
                            ))}
                          </Col>
                      </Row>
                    </CardBody>
                  </Card>
                   }
                {datas.map(item => (
                  <Card class="mb-4 p-1" key={item.id}>
                    <CardBody>
                      <Row>
                        <Col sm={2} class="d-flex flex-column justify-content-center">
                          <h3 className='text-center'>{item.label}</h3>
                        </Col>
                        <Col sm={10} style={{borderLeft: '2px dashed #B8B8B8'}}>
                          {item.children.map(t => childrenComponent(t, this.state.fetchResponses, analyseId, filterVague, zoneId, item.id, this.state.allUnfold))}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                ))}
              </>
            )
          }}
        </FRequest>
      </div>
    )
  }
}

export {DetailedRapport};
export default DetailedRapport;
