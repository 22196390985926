import React from "react";
import {CustomInput} from "reactstrap";
import {DebounceInput} from "@jeremyglanum/ui";
import {Tree, decorators} from "@jeremyglanum/neqo-tree";

const localDecorators = {
  theme: {
    ...decorators,
    Container: ({node, name, terminal, listeners, onClick, decorators}) => {
      return (
        <div class="ocmTree__container">
          {!terminal && !!node.children && !!node.children.length &&
          <decorators.Toggle node={node} onClick={onClick}/>}
          <span class="ocmTree__header">
            {node.type === 'audit' &&
            <span>{node.label}</span>}
            {node.type === 'question' &&
              <span>{!!node.title ? node.title : node.label}</span>}
            {node.type === 'zoneExploitation' &&
            <span>{node.label}</span>}
            {node.type === 'comment' &&
            <CustomInput
              type="checkbox"
              id={`${name}${node.type}${node.id}`}
              className="d-inline-block"
              checked={!!node.checked}
              onChange={listeners.handleCheck}
              label={node.comment} />}
          </span>
        </div>
      )
    }
  }
};

class ResponsesTree extends React.PureComponent {
  state = {
    search: ''
  };

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  onToggle = async (node, toggled) => {
    async function toggleRecursively(node, fetch){
      node.toggled = !node.toggled;
      if (!!fetch)
        await fetch(node, toggled);
      if (!!node.children && node.children.length === 1)
        toggleRecursively(node.children[0], fetch);
    }
    toggleRecursively(node, this.props.fetchResponses);
    this.forceUpdate();
  };

  handleCheck = (node, e) => {
    node.checked = !node.checked;
    this.forceUpdate();
    if (!!this.props.onChange)
      this.props.onChange(this.props.data);
  };

  searchFunc = (data, searchValue, searchFields) => {
    function update(tree){
      tree.forEach(node => {
        node.hiddenBySearch = true;
        node.matched = false;
        for (let i = 0; i < searchFields.length; i++){
          if (!!node[searchFields[i]] && node[searchFields[i]].toLowerCase().includes(searchValue.toLowerCase())){
            node.hiddenBySearch = false;
            node.matched = true;
            node.toggled = true;
            break;
          }
        }
        if (!!node.children && !!node.children.length){
          if (update(node.children)){
            node.hiddenBySearch = false;
            node.toggled = true;
          }
        }
      });
      for (let i = 0; i < tree.length; i++){
        if (!tree[i].hiddenBySearch)
          return true;
      }
      return false;
    }
    update(data);
    //toggle les fils des elements qui ont matchés
    // function expand(tree){
    //   if (!tree)
    //     return;
    //   tree.forEach(item => {
    //     if (!item.hiddenBySearch && !!item.children && !!item.children.length){
    //       const matchedNode = item.children.find(i => !i.hiddenBySearch);
    //       if (!!matchedNode)
    //         expand(item.children);
    //       else
    //         item.children.forEach(item => {
    //           item.toggled = true;
    //           item.hiddenBySearch = false;
    //         });
    //     }
    //   });
    // }
//    expand(data);

    function showMatchedPath(nodes, matched = false){
      nodes.forEach(node => {
        node.hiddenBySearch = !node.matched;
        if (!!matched){
          node.matched = matched;
          node.hiddenBySearch = !matched;
          node.toggled = true;
        }
        if (!!node.children && !!node.children.length){
          showMatchedPath(node.children, !!node.matched);
          if (!!(node.children.find(n => !!n.matched))){
            node.matched = true;
            node.hiddenBySearch = false;
            node.toggled = true;
          }
        }
      });
    }
    showMatchedPath(data);

    // function checkItems(nodes){
    //   nodes.forEach(node => {
    //     node.checked = node.matched;
    //     if (!!node.children && !!node.children.length){
    //       checkItems(node.children);
    //       for (let i = 0; i < node.children.length; i++)
    //         if (!node.children[i].checked){
    //           node.checked = false;
    //           break;
    //         }
    //     }
    //   })
    // }
    //
    // checkItems(data);

    if (!!this.props.onChange)
      this.props.onChange(data);
  };

  render(){
    const {search} = this.state;

    return (
      <div>
        <DebounceInput className="mb-2" value={search} onChange={this.handleChange}/>
        <Tree
          search={true}
          searchValue={search}
          onToggle={this.onToggle}
          searchFunction={this.searchFunc}
          decorators={localDecorators.theme}
          searchFields={['label', 'title', 'comment']}
          listeners={{
            handleCheck: this.handleCheck
          }}
          {...this.props}/>
      </div>
    )
  }
}

export {ResponsesTree};
export default ResponsesTree;
